import PageHeader from 'Shared/Components/PageHeader';
import { useWindowWidthSize, useRequestProcessor } from 'Shared/customHooks';
import Loader from 'Shared/Components/Loader';
import ModalStyled from 'Shared/Components/Modal';
import TitleModal from 'Shared/Components/TitleModal';
import TableStyled from 'Shared/Components/Table';
import ActionsPlatforms from 'Transportation/Platforms/Actions';
import { PlatformsAPI } from 'Shared/Services/platforms';
import { CarOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { columns, expandedRowRender } from './utils';

function Platforms() {
  const { query } = useRequestProcessor();
  const {
    widthDeviceSize,
    isMobile,
  } = useWindowWidthSize();
  const [modalState, setModalState] = useState({
    visibleModal: false,
    title: '',
    action: null,
    data: null,
  });
  const [responsiveDesign, setResponsiveDesign] = useState(false);

  const { data, isLoading } = query(
    'platforms',
    () => PlatformsAPI.getPlatforms(),
    { enabled: true },
  );

  const toggleModal = () => {
    setModalState((currentState) => ({
      ...currentState,
      visibleModal: !currentState.visibleModal,
    }));
  };

  const onSuccess = () => {
    setModalState((currentState) => ({
      title: '',
      action: null,
      visibleModal: !currentState.visibleModal,
    }));
  };

  const handleOnClickCreatePlatforms = () => {
    toggleModal();
    setModalState((currentState) => ({
      ...currentState,
      title: <TitleModal Icon={CarOutlined} title="Crear plataforma" />,
      action: 'create',
      data: null,
    }));
  };

  const handleActions = (menuItemClicked, record) => {
    const { key } = menuItemClicked;

    if (key === 'inactive') {
      setModalState(() => ({
        visibleModal: true,
        title: <TitleModal Icon={CarOutlined} title="Eliminar plataforma" />,
        action: 'inactive',
        data: record,
      }));
    } else if (key === 'update') {
      setModalState(() => ({
        visibleModal: true,
        title: <TitleModal Icon={CarOutlined} title="Actualizar plataforma" />,
        action: 'update',
        data: record,
      }));
    }
  };

  useEffect(() => {
    if (widthDeviceSize <= 1024) setResponsiveDesign(true);
    else setResponsiveDesign(false);
  }, [widthDeviceSize]);

  return (
    <>
      <PageHeader title="Plataformas" />

      <Row justify="end">
        <Col xs={24} lg={24}>
          <Button
            type="primary"
            className="bg-blue-800"
            block={isMobile}
            style={{
              float: 'right',
              marginBottom: '2rem',
            }}
            onClick={handleOnClickCreatePlatforms}
          >
            Crear plataforma
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          { isLoading
            ? <Loader />
            : (
              <TableStyled
                size="middle"
                columns={columns(handleActions)}
                dataSource={data.platformTrucks}
                rowKey={(record) => record.id}
                pagination={{
                  defaultCurrent: 1,
                  total: data.total,
                }}
                expandable={{
                  expandedRowRender,
                  expandRowByClick: true,
                  showExpandColumn: true,
                }}
                scroll={{ x: responsiveDesign }}
              />
            )}
        </Col>
      </Row>

      <ModalStyled
        open={modalState.visibleModal}
        centered
        title={modalState.title}
        onCancel={toggleModal}
        footer={null}
      >
        <ActionsPlatforms
          action={modalState.action}
          onSuccess={onSuccess}
          mobileDevice={isMobile}
          data={modalState.data}
        />
      </ModalStyled>
    </>
  );
}

export default Platforms;
