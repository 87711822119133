import { useRequestProcessor } from 'Shared/customHooks';
import { TransactionsAPI } from 'Shared/Services/transactions';
import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const SelectUnities = React.forwardRef(({ ...props }, ref) => {
  const {
    style,
    disabled,
    filter,
    beingFiltered,
  } = props;

  const { query } = useRequestProcessor();

  const { data, isLoading } = query(
    ['getTransactionsCategories'],
    () => TransactionsAPI.getTransactionsCategories(),
    { enabled: !disabled },
  );

  return (
    <Select
      {...props}
      ref={ref}
      style={{
        ...style,
        width: style && style.width ? style.width : '100%',
      }}
      disabled={disabled}
      isLoading={isLoading}
    >
      {beingFiltered && (
        <Option key="all" value="all">Todos</Option>
      )}
      {data && data.categoriesTransactions.map((item) => (
        <Option key={item.id} value={item.id} filter={filter}>
          {item.name}
        </Option>
      ))}
    </Select>
  );
});

export default SelectUnities;
