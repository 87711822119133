import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useLayoutEffect, useState } from 'react';

export function useWindowWidthSize(breakpointMobile = 768) {
  const [widthDeviceSize, setWidthDeviceSize] = useState(0);
  const [isMobile, setIsMobile] = useState(true);

  useLayoutEffect(() => {
    const updateSize = () => {
      const widthDevice = window.innerWidth;
      setWidthDeviceSize(widthDevice);
      if (widthDevice <= breakpointMobile) setIsMobile(true);
      else setIsMobile(false);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return {
    widthDeviceSize,
    isMobile,
  };
}

export function useRequestProcessor() {
  const queryClient = useQueryClient();

  function query(key, queryFunction, options = {}, params = null) {
    let nameKey = [key];
    if (params) nameKey = [key, params];

    return useQuery({
      queryKey: nameKey,
      queryFn: queryFunction,
      ...options,
    });
  }

  function mutation(key, mutationFunction, options = {}) {
    return useMutation({
      mutationKey: [key],
      mutationFn: mutationFunction,
      onSettled: () => queryClient.invalidateQueries(key),
      ...options,
    });
  }

  return { query, mutation };
}
