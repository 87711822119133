import styled from 'styled-components';
import React from 'react';

const PageHeaderStyled = styled.h1`
  font-variant: normal;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 50px;
  color: #1D1D1B;
  font-weight: 200;

  @media (min-width: 375px){
    font-size: 30px;
  }
`;

function PageHeader(props) {
  const { title } = props;

  return (
    <PageHeaderStyled>
      {title}
    </PageHeaderStyled>
  );
}

PageHeader.defaultProps = {
  title: 'Title',
};

const SubPageHeaderStyled = styled.h2`
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 20px;
  color: #1D1D1B;
  font-weight: bold;
`;

function SubPageHeader(props) {
  const { title } = props;

  return (
    <SubPageHeaderStyled>
      {title}
    </SubPageHeaderStyled>
  );
}

export default PageHeader;

export {
  SubPageHeader,
};
