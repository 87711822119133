import axiosConfig from 'Shared/Utils/axiosConfig';

function login(params) {
  return axiosConfig.post('auth/login', params)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function register(params) {
  return axiosConfig.post('auth/registration', params)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function refresh() {
  return axiosConfig.post('auth/refresh')
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function logout() {
  return axiosConfig.post('auth/logout')
    .then((response) => {
      const { data } = response;
      return data;
    });
}

export const AuthAPI = {
  login,
  register,
  refresh,
  logout,
};
