import axiosConfig from 'Shared/Utils/axiosConfig';

function getTransactionsCategories() {
  return axiosConfig.get('categories-transactions')
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function createTransaction(params) {
  return axiosConfig.post('transactions', params)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function getTransactions(params = {}) {
  return axiosConfig.get('transactions', { params })
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function getExpensesSum(params = {}) {
  return axiosConfig.get('categories-expenses', { params })
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function updateClient(clientId, params) {
  return axiosConfig.patch(`clients/${clientId}`, params)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function removeClient(clientId) {
  return axiosConfig.delete(`clients/${clientId}`)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

export const TransactionsAPI = {
  getTransactionsCategories,
  createTransaction,
  getTransactions,
  getExpensesSum,
  updateClient,
  removeClient,
};
