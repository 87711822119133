import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const SelectUserRoles = React.forwardRef(({ ...props }, ref) => {
  const { style, disabled, filter } = props;

  const dataSource = [
    { name: 'Administrador', value: 1 },
    { name: 'Usuario normal', value: 2 },
  ];

  return (
    <Select
      {...props}
      ref={ref}
      style={{
        ...style,
        width: style && style.width ? style.width : '100%',
      }}
      disabled={disabled}
    >
      {dataSource.map((rol) => (
        <Option key={rol.value} value={rol.value} filter={filter}>
          {rol.name}
        </Option>
      ))}
    </Select>
  );
});

export default SelectUserRoles;
