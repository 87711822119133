import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const SpinStyled = styled(Spin)`
  display: block;
  margin: 0 auto;
`;

function Loader() {
  return <SpinStyled indicator={<LoadingOutlined style={{ fontSize: 52 }} spin />} />;
}

export default Loader;
