import styled from 'styled-components';
import { Card } from 'antd';

const CardFinances = styled(Card)`
  width: 300px;
`;

export {
  CardFinances,
};
