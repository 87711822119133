import { useRequestProcessor } from 'Shared/customHooks';
import FormItem from 'Shared/Components/Form/Item';
import { SubtitleExpandedRowStyled } from 'Shared/Components/TitleExpandedRow';
import DescriptionsStyled from 'Shared/Components/Descriptions';
import { InsurancesAPI } from 'Shared/Services/insurances';
import DatePickerStyled from 'Shared/Components/DatePicker';
import { dateFormat } from 'Shared/Utils';
import {
  Row, Col, Button, Form, Input, notification, InputNumber,
} from 'antd';
import React from 'react';
import dayjs from 'dayjs';

function RenewInsurances(props) {
  const {
    mobileDevice,
    data: insurance,
    onSuccess,
  } = props;
  const { mutation } = useRequestProcessor();
  const [apiNotification, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const {
    isLoading,
    mutate,
  } = mutation('insurance-renew', (values) => InsurancesAPI.createInsurance(values));

  const onFinish = async (values) => {
    values.startDate = values.startDate.format('YYYY-MM-DD HH:mm:ss');
    values.endDate = values.endDate.format('YYYY-MM-DD HH:mm:ss');
    values.renew = true;
    values.insuranceID = insurance.id;

    if (insurance.platformTruck) {
      values.platformTruckId = insurance.platformTruck.id;
      values.transport = 2;
    } else if (insurance.truck) {
      values.truckId = insurance.truck.id;
      values.transport = 1;
    }

    mutate(values, {
      onSuccess: (data) => {
        const { message } = data;
        apiNotification.success({
          description: message,
        });
        form.resetFields();
        onSuccess();
      },
      onError: (error) => {
        const { data } = error.response;
        const { message } = data;
        apiNotification.error({
          description: message,
        });
      },
    });
  };

  return (
    <>
      {contextHolder}

      <DescriptionsStyled title={<SubtitleExpandedRowStyled>Póliza a renovar</SubtitleExpandedRowStyled>} bordered size="small">
        <DescriptionsStyled.Item span={3} label="Descripción">
          {insurance.description}
        </DescriptionsStyled.Item>
        {insurance.truck && (
          <DescriptionsStyled.Item span={3} label="Unidad">
            {`${insurance.truck.reference} / ${insurance.truck.name}`}
          </DescriptionsStyled.Item>
        )}
        {insurance.platformTruck && (
          <DescriptionsStyled.Item span={3} label="Plataforma">
            {`${insurance.platformTruck.reference} / ${insurance.platformTruck.name} / ${insurance.platformTruck.brand}`}
          </DescriptionsStyled.Item>
        )}
        <DescriptionsStyled.Item span={3} label="Vigencia vencida inicial">
          {dateFormat(insurance.startDate)}
        </DescriptionsStyled.Item>
        <DescriptionsStyled.Item span={3} label="Vigencia vencida final">
          {dateFormat(insurance.endDate)}
        </DescriptionsStyled.Item>
      </DescriptionsStyled>
      <Form
        form={form}
        className="login-form"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          description: insurance.description,
          startDate: dayjs(insurance.endDate).add(1, 'd'),
          endDate: dayjs(insurance.endDate).add(1, 'd').add(1, 'y'),
        }}
      >
        <Row type="flex" gutter={20} className="mt-5">
          <Col xs={24} md={12}>
            <FormItem
              label="Fecha inicial sugerida"
              name="startDate"
              rules={[{ required: true, message: 'Selecciona una fecha inicial de la vigencia' }]}
            >
              <DatePickerStyled
                placeholder="Selecciona la fecha inicial"
                onChange={(date) => date.format('YYYY-MM-DD HH:mm:ss')}
              />
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem
              label="Fecha final sugerida"
              name="endDate"
              rules={[{ required: true, message: 'Selecciona una fecha final de la vigencia' }]}
            >
              <DatePickerStyled
                placeholder="Selecciona la fecha final"
                disabledDate={(current) => current && form.getFieldValue('startDate') > current}
              />
            </FormItem>
          </Col>
          <Col xs={24}>
            <FormItem
              label="Descripción de la póliza sugerida"
              name="description"
              rules={[{ required: true, message: 'Ingresa una descripción de la póliza' }]}
            >
              <Input.TextArea
                autoSize={{
                  minRows: 3,
                  maxRows: 3,
                }}
              />
            </FormItem>
          </Col>
          <Col xs={24}>
            <FormItem
              label="Costo"
              name="price"
              rules={[
                { required: true, message: 'Ingresa el costo de la póliza.' },
              ]}
            >
              <InputNumber
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                className="w-full"
              />
            </FormItem>
          </Col>

        </Row>
        <Row type="flex" justify="end">
          <Col>
            <Button
              type="primary"
              className="bg-blue-800"
              loading={isLoading}
              htmlType="submit"
              block={mobileDevice}
              style={{ marginTop: '1.3rem' }}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default RenewInsurances;
