import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const data = [
  {
    id: 1,
    value: 'Carga suelta',
  },
  {
    id: 2,
    value: 'Contenerizada',
  },
];

const SelectCarga = React.forwardRef(({ ...props }, ref) => {
  const { style, disabled, filter } = props;

  return (
    <Select
      {...props}
      ref={ref}
      style={{
        ...style,
        width: style && style.width ? style.width : '100%',
      }}
      disabled={disabled}
    >
      {data && data.map((item) => (
        <Option key={item.id} value={item.value} filter={filter}>
          {item.value}
        </Option>
      ))}
    </Select>
  );
});

export default SelectCarga;
