import Actions from 'Shared/Components/Actions';
import TitleExpandedRowStyled from 'Shared/Components/TitleExpandedRow';
import { dateFormat, daysBetweenDates } from 'Shared/Utils';
import DescriptionsStyled from 'Shared/Components/Descriptions';
import { Tooltip } from 'antd';
import { DeleteFilled, EditFilled, ExclamationCircleTwoTone } from '@ant-design/icons';
import React, { Fragment } from 'react';

const items = (handleActions, record) => [
  {
    label: 'Inactivar',
    key: 'inactive',
    icon: <DeleteFilled style={{ color: '#D11A2A' }} />,
    onClick: (item) => {
      handleActions(item, record);
    },
  },
  {
    label: 'Actualizar',
    key: 'update',
    icon: <EditFilled style={{ color: '#AD2AA5' }} />,
    onClick: (item) => {
      handleActions(item, record);
    },
  },
];

export const columns = (handleActions) => [
  {
    title: 'ID',
    dataIndex: 'reference',
    width: 100,
    sorter: (a, b) => a.reference.length - b.reference.length,
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    sorter: (a, b) => a.name.length - b.name.length,
  },
  {
    title: 'Unidad',
    dataIndex: ['truck', 'name'],
    sorter: (a, b) => a.truck.name.length - b.truck.name.length,
  },
  {
    key: 'action',
    width: 1,
    render: (record) => (
      <Actions
        menu={{
          items: items(handleActions, record),
        }}
        placement="bottomRight"
      />
    ),
  },
];

export const expandedRowRender = (record, index, indent, expanded) => {
  let render = null;
  if (expanded) {
    const today = new Date();
    render = (
      <DescriptionsStyled title={<TitleExpandedRowStyled>{record.name}</TitleExpandedRowStyled>} bordered size="small">
        <DescriptionsStyled.Item span={2} label="Nombre">{record.name}</DescriptionsStyled.Item>
        <DescriptionsStyled.Item label="Marca">{record.brand}</DescriptionsStyled.Item>
        <DescriptionsStyled.Item label="Modelo">{record.model}</DescriptionsStyled.Item>
        <DescriptionsStyled.Item label="Placas">{record.tag}</DescriptionsStyled.Item>
        <DescriptionsStyled.Item label="No. Eco">
          {record.ecoNumber}
        </DescriptionsStyled.Item>
        <DescriptionsStyled.Item label="Tarjeta de circulación">
          {record.vin}
        </DescriptionsStyled.Item>
        <DescriptionsStyled.Item span={2} label="No. Serie">
          {record.serieNumber}
        </DescriptionsStyled.Item>

        <DescriptionsStyled.Item span={3} label="Dimensiones" contentStyle={{ padding: 0 }}>
          <DescriptionsStyled bordered size="small" layout="vertical">
            <DescriptionsStyled.Item label="Altura">
              {record.height}
              {' '}
              pies
            </DescriptionsStyled.Item>
            <DescriptionsStyled.Item label="Ancho">
              {record.width}
              {' '}
              pies
            </DescriptionsStyled.Item>
            <DescriptionsStyled.Item label="Largo">
              {record.length}
              {' '}
              pies
            </DescriptionsStyled.Item>
          </DescriptionsStyled>
        </DescriptionsStyled.Item>
        <DescriptionsStyled.Item label="Pólizas" contentStyle={{ padding: 0 }}>
          <DescriptionsStyled bordered size="small" layout="vertical">
            {record.insurancePolicies.map((policy) => {
              const { description, startDate, endDate } = policy;
              const days = daysBetweenDates(endDate, today);
              let showWarning = false;
              let colorIcon = '';

              if (days < 32) {
                showWarning = true;
                colorIcon = '#cc3300';
              } else if (days < 90) {
                showWarning = true;
                colorIcon = '#c69035';
              }
              return (
                <Fragment key={`${policy.id}-${record.name}`}>
                  <DescriptionsStyled.Item label="Descripción">
                    {showWarning && (
                      <Tooltip
                        title={() => (
                          <>
                            Restan
                            {' '}
                            <strong>{days}</strong>
                            {' '}
                            días de vigencia de la póliza.
                            {' '}
                            Renueva los datos de la póliza.
                          </>
                        )}
                        destroyTooltipOnHide
                        defaultOpen
                      >
                        <ExclamationCircleTwoTone
                          twoToneColor={colorIcon}
                          style={{
                            marginRight: 5,
                          }}
                        />
                      </Tooltip>
                    )}

                    {description}
                  </DescriptionsStyled.Item>
                  <DescriptionsStyled.Item label="Fecha inicial">
                    {dateFormat(startDate)}
                  </DescriptionsStyled.Item>
                  <DescriptionsStyled.Item label="Fecha final">
                    {dateFormat(endDate)}
                  </DescriptionsStyled.Item>
                </Fragment>
              );
            })}
          </DescriptionsStyled>
        </DescriptionsStyled.Item>
      </DescriptionsStyled>
    );
  }

  return render;
};
