import Actions from 'Shared/Components/Actions';
import { daysBetweenDates, moneyFormat } from 'Shared/Utils';
import { RedoOutlined } from '@ant-design/icons';
import React from 'react';
import dayjs from 'dayjs';

function DisabledRenew(record) {
  const { endDate } = record;

  return dayjs().isBefore(
    dayjs(endDate),
  );
}

const items = (handleActions, record) => [
  {
    label: 'Renovar',
    key: 'renew',
    disabled: DisabledRenew(record),
    icon: <RedoOutlined style={{ color: '#D11A2A' }} />,
    onClick: (item) => {
      handleActions(item, record);
    },
  },
];

export const columns = (handleActions) => [
  {
    title: 'ID',
    dataIndex: '',
    fixed: 'left',
    render: (text, record) => {
      let textFormat = 'Póliza no asociada a unidad/plataforma.';
      const { truck, platformTruck } = record;

      if (truck) {
        const { reference } = truck;
        textFormat = `Unidad ${reference}`;
      } else if (platformTruck) {
        const { reference } = platformTruck;
        textFormat = `Plataforma ${reference}`;
      }
      return textFormat;
    },
  },
  {
    title: 'Descripción',
    dataIndex: 'description',
    sorter: (a, b) => a.description.length - b.description.length,
  },
  {
    title: 'Costo',
    dataIndex: 'price',
    align: 'center',
    width: 150,
    sorter: (a, b) => a.price.length - b.price.length,
    render: (text) => moneyFormat(text),
  },
  {
    title: 'Fecha inicial',
    dataIndex: 'startDate',
    sorter: (a, b) => a.startDate.length - b.startDate.length,
    align: 'center',
    render: (text) => {
      let date = new Date(text);
      date = date.toLocaleDateString('es-MX', {
        month: 'long',
        day: '2-digit',
        year: 'numeric',
      });

      return date;
    },
  },
  {
    title: 'Fecha final',
    dataIndex: 'endDate',
    align: 'center',
    sorter: (a, b) => a.endDate.length - b.endDate.length,
    render: (text) => {
      let date = new Date(text);
      date = date.toLocaleDateString('es-MX', {
        month: 'long',
        day: '2-digit',
        year: 'numeric',
      });

      return date;
    },
  },
  {
    title: 'Días restantes',
    align: 'center',
    width: 140,
    render: (record) => {
      const today = new Date();
      const days = daysBetweenDates(record.endDate, today);

      return days;
    },
  },
  {
    key: 'action',
    width: 30,
    fixed: 'right',
    render: (record) => (
      <Actions
        menu={{
          items: items(handleActions, record),
        }}
        placement="bottomRight"
      />
    ),
  },
];

export const transformDataInsurancePolicies = (data) => {
  const newData = data.filter((item) => !item.renew);

  return newData;
};
