import axiosConfig from 'Shared/Utils/axiosConfig';

function getDrivers() {
  return axiosConfig.get('drivers')
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function createDriver(params) {
  return axiosConfig.post('drivers', params)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function updateDriver(driverId, params) {
  return axiosConfig.patch(`drivers/${driverId}`, params)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function removeDriver(driverId) {
  return axiosConfig.delete(`drivers/${driverId}`)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

export const DriversAPI = {
  getDrivers,
  createDriver,
  updateDriver,
  removeDriver,
};
