import { useRequestProcessor } from 'Shared/customHooks';
import FormItem from 'Shared/Components/Form/Item';
import SelectTrucks from 'Shared/Selects/Trucks';
import { PlatformsAPI } from 'Shared/Services/platforms';
import {
  Row, Col, Button, Form, Input, notification, InputNumber,
} from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import React, { useEffect } from 'react';

function CreateUpdatePlatforms(props) {
  const {
    mobileDevice,
    onSuccess,
    typeAction,
    data,
  } = props;
  const { mutation } = useRequestProcessor();
  const [apiNotification, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const {
    isLoading: isLoadingCreate,
    mutate: mutateCreate,
  } = mutation('createPlatform', (values) => PlatformsAPI.createPlatform(values));

  const {
    isLoading: isLoadingUpdate,
    mutate: mutateUpdate,
  } = mutation('updatePlatform', ({ id, formatValues }) => PlatformsAPI.updatePlatform(id, formatValues));

  const onFinish = async (values) => {
    if (typeAction === 'create') {
      mutateCreate(values, {
        onSuccess: (response) => {
          const { message } = response;
          apiNotification.success({
            description: message,
          });
          form.resetFields();
          onSuccess();
        },
        onError: (error) => {
          const { data: info } = error.response;
          const { message } = info;
          apiNotification.error({
            description: message,
          });
        },
      });
    } else if (typeAction === 'update') {
      const { id } = data;

      const formatValues = {
        ...values,
        truckId: values.truck.id,
      };
      delete formatValues.truck;

      mutateUpdate({
        id,
        formatValues,
      }, {
        onSuccess: (response) => {
          const { message } = response;
          apiNotification.success({
            description: message,
          });
          form.resetFields();
          onSuccess();
        },
        onError: (error) => {
          const { data: info } = error.response;
          const { message } = info;
          apiNotification.error({
            description: message,
          });
        },
      });
    }
  };

  useEffect(() => {
    if (typeAction === 'create') form.resetFields();
    else form.setFieldsValue(data);
  }, [form, data, typeAction]);

  return (
    <>
      {contextHolder}
      <Form
        className="login-form"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={data}
      >
        <Row type="flex" gutter={20}>
          <Col xs={24} md={12}>
            <FormItem
              label="ID"
              name="reference"
              rules={[
                { required: true, message: 'Ingresa el ID de la plataforma.' },
              ]}
            >
              <Input />
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem
              label="Nombre"
              name="name"
              rules={[
                { required: true, message: 'Ingresa el nombre.' },
              ]}
            >
              <Input />
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem
              label="Marca"
              name="brand"
              rules={[
                { required: true, message: 'Ingresa la marca.' },
              ]}
            >
              <Input />
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem
              label="Modelo"
              name="model"
              rules={[
                { required: true, message: 'Ingresa el modelo.' },
                {
                  required: true,
                  pattern: /^[0-9]*$/,
                  message:
                    'Debes ingresar solo numeros.',
                },
              ]}
            >
              <Input />
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem
              label="Placas"
              name="tag"
              rules={[
                { required: true, message: 'Ingresa las placas.' },
                {
                  required: true,
                  max: 10,
                  message:
                    'Debes ingresar máximo 10 caracteres.',
                },
              ]}
            >
              <Input />
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem
              label="No. Eco"
              name="ecoNumber"
              rules={[
                { required: true, message: 'Ingresa el No. Eco.' },
                {
                  required: true,
                  pattern: /^[0-9]*$/,
                  message:
                    'Debes ingresar solo numeros.',
                },
              ]}
            >
              <Input />
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem
              label="Tarjeta de circulación"
              name="vin"
              rules={[
                { required: true, message: 'Ingresa la tarjeta de circulación.' },
              ]}
            >
              <Input />
            </FormItem>
          </Col>
          <Col xs={12}>
            <FormItem
              label="No. Serie"
              name="serieNumber"
              rules={[
                { required: true, message: 'Ingresa el número de serie.' },
              ]}
            >
              <Input />
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <FormItem
              label="Largo"
              name="length"
              tooltip={{
                title: 'Recuerda que la medida es en pies.',
                placement: 'left',
                icon: <InfoCircleTwoTone twoToneColor="#003EA0" />,
              }}
              rules={[
                { required: true, message: 'Ingresa el largo de la plataforma.' },
              ]}
            >
              <InputNumber style={{ width: '100%' }} />
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <FormItem
              label="Ancho"
              name="width"
              tooltip={{
                title: 'Recuerda que la medida es en pies.',
                placement: 'left',
                icon: <InfoCircleTwoTone twoToneColor="#003EA0" />,
              }}
              rules={[
                { required: true, message: 'Ingresa el ancho de la plataforma.' },
              ]}
            >
              <InputNumber style={{ width: '100%' }} />
            </FormItem>
          </Col>
          <Col xs={24} md={8}>
            <FormItem
              label="Altura"
              name="height"
              tooltip={{
                title: 'Recuerda que la medida es en pies.',
                placement: 'left',
                icon: <InfoCircleTwoTone twoToneColor="#003EA0" />,
              }}
              rules={[
                { required: true, message: 'Ingresa la altura de la plataforma.' },
              ]}
            >
              <InputNumber style={{ width: '100%' }} />
            </FormItem>
          </Col>
          <Col xs={24}>
            <FormItem
              name={['truck', 'id']}
              tooltip={{
                title: 'La plataforma estará relacionada con una unidad.',
                placement: 'left',
                icon: <InfoCircleTwoTone twoToneColor="#003EA0" />,
              }}
              label="Selecciona una unidad"
              rules={[{ required: true, message: 'Selecciona una unidad' }]}
            >
              <SelectTrucks />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <Col>
            <Button
              type="primary"
              className="bg-blue-800"
              loading={isLoadingCreate || isLoadingUpdate}
              htmlType="submit"
              block={mobileDevice}
              style={{ marginTop: '1.3rem' }}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default CreateUpdatePlatforms;
