import PageHeader from 'Shared/Components/PageHeader';
import { useWindowWidthSize, useRequestProcessor } from 'Shared/customHooks';
import Loader from 'Shared/Components/Loader';
import ModalStyled from 'Shared/Components/Modal';
import TitleModal from 'Shared/Components/TitleModal';
import ActionsScheduled from 'Transportation/Scheduled/Actions';
import { DayTitle, ListScheduledTrucks } from 'Transportation/Scheduled/ListItemRender';
import { ScheduledTrucksAPI } from 'Shared/Services/scheduledTrucks';
import { Calendar as BigCalendar, dayjsLocalizer } from 'react-big-calendar';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { CarOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Button,
  Calendar,
  Result,
} from 'antd';
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import 'dayjs/locale/es-mx';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './overrideBigCalendar.css';

dayjs.extend(weekday);
dayjs.extend(localeData);

const localizerDayJs = dayjsLocalizer(dayjs);

function ScheduledTrucks() {
  const { query } = useRequestProcessor();
  const {
    isMobile,
  } = useWindowWidthSize();
  const [modalState, setModalState] = useState({
    visibleModal: false,
    title: '',
    action: null,
    data: null,
  });
  const [calendarValue, setCalendarValue] = useState(
    () => dayjs(),
  );
  const [calendarSelectedValue, setCalendarSelectedValue] = useState(
    () => dayjs(),
  );
  const [queryParams, setQueryParams] = useState({
    date: calendarValue.format('YYYY-MM-DD'),
  });
  const [bigCalendarDate, setBigCalendarDate] = useState(
    () => dayjs(),
  );
  const [queryParamsBigCalendar, setQueryParamsBigCalendar] = useState(undefined);

  const { data, isLoading } = query(
    'trucks',
    () => ScheduledTrucksAPI.getScheduledTrucks(queryParams),
    { enabled: true },
    queryParams,
  );

  const { data: dataBigCalendar, isLoading: isLoadingBigCalendar } = query(
    'trucks-big-calendar',
    () => ScheduledTrucksAPI.getScheduledTrucks(queryParamsBigCalendar),
    { enabled: !!queryParamsBigCalendar },
    queryParamsBigCalendar,
  );

  const toggleModal = () => {
    setModalState((currentState) => ({
      ...currentState,
      visibleModal: !currentState.visibleModal,
    }));
  };

  const onSuccess = () => {
    setModalState((currentState) => ({
      title: '',
      action: null,
      visibleModal: !currentState.visibleModal,
    }));
  };

  const handleOnClickCreateTrucks = () => {
    toggleModal();
    setModalState((currentState) => ({
      ...currentState,
      title: <TitleModal Icon={CarOutlined} title="Asignar unidad a taller" />,
      action: 'create',
      data: null,
    }));
  };

  const onSelect = (newValue) => {
    setCalendarValue(newValue);
    setCalendarSelectedValue(newValue);

    setQueryParams({
      ...queryParams,
      date: newValue.format('YYYY-MM-DD'),
    });
  };

  const onPanelChange = (newValue) => {
    setCalendarValue(newValue);
  };

  const updateTimes = (date, view) => {
    let startDate;
    let endDate;

    if (view === 'agenda') {
      startDate = dayjs(date).startOf('day');
      endDate = dayjs(date).endOf('day').add(1, 'month');
    } else if (view === 'month') {
      startDate = dayjs(date).startOf('month').subtract(7, 'days');
      endDate = dayjs(date).endOf('month').add(7, 'days');
    }
    startDate = startDate.format('YYYY-MM-DD');
    endDate = endDate.format('YYYY-MM-DD');

    setQueryParamsBigCalendar({
      startDate,
      endDate,
    });
  };

  const onNavigate = useCallback((date, view) => {
    const newDate = dayjs(date);
    setBigCalendarDate(newDate);

    updateTimes(newDate, view);
  }, [setBigCalendarDate]);

  const { views, messages, formats } = useMemo(() => ({
    views: ['month', 'agenda'],
    messages: {
      day: 'Día',
      month: 'Mes',
      previous: 'Previo',
      next: 'Siguiente',
      today: 'Hoy',
      agenda: 'Agenda',
      date: 'Fecha',
      time: 'Duración',
      event: 'Suceso',
      noEventsInRange: 'No hay sucesos.',
      showMore: (total) => `+${total} más`,
    },
    formats: {
      monthHeaderFormat: (date) => dayjs(date).locale('es-mx').format('MMMM YYYY'),
      agendaHeaderFormat: ({ start, end }) => `${dayjs(start).locale('es-mx').format('DD/MMMM/YY')} - ${dayjs(end).locale('es-mx').format('DD/MMMM/YY')}`,
      agendaDateFormat: (date) => dayjs(date).locale('es-mx').format('dddd, DD MMMM'),
      weekdayFormat: (date) => dayjs(date).locale('es-mx').format('dddd'),
    },
  }), []);

  useEffect(() => {
    updateTimes(bigCalendarDate, 'agenda');
  }, []);

  return (
    <>
      <PageHeader title="Asignación de unidades" />

      <Row justify="end" gutter={[0, 12]}>
        <Col xs={24} md={8}>
          <Button
            type="primary"
            className="bg-blue-800"
            block={isMobile}
            style={{
              float: 'right',
              marginBottom: '2rem',
            }}
            onClick={handleOnClickCreateTrucks}
          >
            Asignar unidad a taller
          </Button>
        </Col>
      </Row>

      {isLoadingBigCalendar ? (
        <Row>
          <Col xs={24}>
            <Loader />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={24}>
            <BigCalendar
              localizer={localizerDayJs}
              date={bigCalendarDate}
              onNavigate={onNavigate}
              defaultView="agenda"
              events={dataBigCalendar}
              views={views}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 800 }}
              messages={messages}
              formats={formats}
            />
          </Col>
        </Row>
      )}

      {isLoading ? (
        <Row style={{ marginTop: '2rem' }} gutter={[48, 24]}>
          <Col xs={24} sm={24} md={12}>
            <Loader />
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Loader />
          </Col>
        </Row>
      ) : (
        <Row style={{ marginTop: '2rem' }} gutter={[48, 24]}>
          <Col xs={24} sm={24} md={12}>
            <Calendar
              fullscreen={false}
              value={calendarValue}
              onSelect={onSelect}
              onPanelChange={onPanelChange}
            />
          </Col>
          <Col xs={24} sm={24} md={12}>
            <DayTitle>
              {`${calendarSelectedValue?.format('DD MMMM YYYY')}`}
            </DayTitle>

            {data.assignTrucks.length > 0 ? (
              <ListScheduledTrucks dataList={data.assignTrucks} />
            ) : (
              <Result
                title="No hay ninguna unidad asignada para esta fecha."
              />
            )}
          </Col>
        </Row>
      )}

      <ModalStyled
        open={modalState.visibleModal}
        centered
        title={modalState.title}
        onCancel={toggleModal}
        footer={null}
      >
        <ActionsScheduled
          action={modalState.action}
          onSuccess={onSuccess}
          mobileDevice={isMobile}
          data={modalState.data}
        />
      </ModalStyled>
    </>
  );
}

export default ScheduledTrucks;
