function randomPassword(length, strong) {
  let characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

  if (strong === 'medium') characters += '0123456789';
  if (strong === 'hard') characters += '0123456789!@#$%^&*_-+=';

  let password = '';
  for (let i = 0; i < length; i += 1) {
    password += characters.charAt(
      Math.floor(Math.random() * characters.length),
    );
  }
  return password;
}

export default randomPassword;
