import CreateRequestService from 'Services/Request/Actions/Create';
import DeleteDrivers from 'Contacts/Drivers/Actions/Delete';
import React from 'react';

const ActionsRequestDrivers = (props) => {
  const {
    action, mobileDevice, onSuccess, data,
  } = props;
  const renderAction = (typeAction) => {
    let actionRender = null;
    if (typeAction === 'create' || typeAction === 'update') {
      actionRender = (
        <CreateRequestService
          mobileDevice={mobileDevice}
          onSuccess={onSuccess}
          data={data}
          typeAction={typeAction}
        />
      );
    } else if (typeAction === 'inactive') {
      actionRender = (
        <DeleteDrivers
          data={data}
          mobileDevice={mobileDevice}
          onSuccess={onSuccess}
        />
      );
    }

    return actionRender;
  };

  return renderAction(action);
};

export default ActionsRequestDrivers;
