import axiosConfig from 'Shared/Utils/axiosConfig';

function getVendors() {
  return axiosConfig.get('vendors')
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function createVendor(params) {
  return axiosConfig.post('vendors', params)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function updateVendor(vendorId, params) {
  return axiosConfig.patch(`vendors/${vendorId}`, params)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function removeVendor(vendorId) {
  return axiosConfig.delete(`vendors/${vendorId}`)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

export const VendorsAPI = {
  getVendors,
  createVendor,
  updateVendor,
  removeVendor,
};
