import Actions from 'Shared/Components/Actions';
import TitleExpandedRowStyled, { SubtitleExpandedRowStyled } from 'Shared/Components/TitleExpandedRow';
import DescriptionsStyled from 'Shared/Components/Descriptions';
import { dateFormat, daysBetweenDates } from 'Shared/Utils';
import { Tooltip } from 'antd';
import { DeleteFilled, EditFilled, ExclamationCircleTwoTone } from '@ant-design/icons';
import React, { Fragment } from 'react';

const items = (handleActions, record) => [
  {
    label: 'Inactivar',
    key: 'inactive',
    icon: <DeleteFilled style={{ color: '#D11A2A' }} />,
    onClick: (item) => {
      handleActions(item, record);
    },
  },
  {
    label: 'Actualizar',
    key: 'update',
    icon: <EditFilled style={{ color: '#AD2AA5' }} />,
    onClick: (item) => {
      handleActions(item, record);
    },
  },
];

export const columns = (handleActions) => [
  {
    title: 'ID',
    dataIndex: 'reference',
    width: 100,
    sorter: (a, b) => a.reference.length - b.reference.length,
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    sorter: (a, b) => a.fullName.length - b.fullName.length,
  },
  {
    key: 'action',
    width: 1,
    render: (record) => (
      <Actions
        menu={{
          items: items(handleActions, record),
        }}
        placement="bottomRight"
      />
    ),
  },
];

export const expandedRowRender = (record, index, indent, expanded) => {
  let render = null;
  if (expanded) {
    const {
      name,
      ecoNumber,
      classTruck,
      engineNumber,
      serieNumber,
      tag,
      type,
      vin,
      platformTrucks,
      insurancePolicies,
    } = record;
    const today = new Date();

    render = (
      <>
        <DescriptionsStyled
          title={(
            <TitleExpandedRowStyled>
              {`(${record.reference}) ${record.name}`}
            </TitleExpandedRowStyled>
          )}
          bordered
          size="small"
        >
          <DescriptionsStyled.Item label="Nombre">{name}</DescriptionsStyled.Item>
          <DescriptionsStyled.Item label="No. Eco">{ecoNumber}</DescriptionsStyled.Item>
          <DescriptionsStyled.Item label="Clase">{classTruck}</DescriptionsStyled.Item>
          <DescriptionsStyled.Item label="No. Motor">{engineNumber}</DescriptionsStyled.Item>
          <DescriptionsStyled.Item label="No. Serie">
            {serieNumber}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item label="Placas">
            {tag}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item span={2} label="Tarjeta de circulación">
            {vin}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item label="Tipo">
            {type}
          </DescriptionsStyled.Item>
        </DescriptionsStyled>
        <DescriptionsStyled title={<SubtitleExpandedRowStyled>Plataformas</SubtitleExpandedRowStyled>} bordered size="small">
          {Array.isArray(platformTrucks) && platformTrucks.length > 0
            ? platformTrucks.map((plat) => {
              const {
                brand,
                ecoNumber: platformEcoNumber,
                model,
                name: platformName,
                serieNumber: platformSerieNumber,
                vin: platformVin,
                tag: platformTag,
                width,
                height,
                length,
              } = plat;
              return (
                <Fragment key={`${plat.id}-${record.name}-${platformName}`}>
                  <DescriptionsStyled.Item span={2} label="Nombre">{platformName}</DescriptionsStyled.Item>
                  <DescriptionsStyled.Item label="Marca">{brand}</DescriptionsStyled.Item>
                  <DescriptionsStyled.Item label="Modelo">{model}</DescriptionsStyled.Item>
                  <DescriptionsStyled.Item label="Placas">{platformTag}</DescriptionsStyled.Item>
                  <DescriptionsStyled.Item label="No. Eco">
                    {platformEcoNumber}
                  </DescriptionsStyled.Item>
                  <DescriptionsStyled.Item span={3} label="Tarjeta de circulación">
                    {platformVin}
                  </DescriptionsStyled.Item>
                  <DescriptionsStyled.Item span={2} label="No. Serie">
                    {platformSerieNumber}
                  </DescriptionsStyled.Item>
                  <DescriptionsStyled.Item span={3} label="Dimensiones" contentStyle={{ padding: 0 }}>
                    <DescriptionsStyled bordered size="small" layout="vertical">
                      <DescriptionsStyled.Item label="Altura">
                        {height}
                        {' '}
                        pies
                      </DescriptionsStyled.Item>
                      <DescriptionsStyled.Item label="Ancho">
                        {width}
                        {' '}
                        pies
                      </DescriptionsStyled.Item>
                      <DescriptionsStyled.Item label="Largo">
                        {length}
                        {' '}
                        pies
                      </DescriptionsStyled.Item>
                    </DescriptionsStyled>
                  </DescriptionsStyled.Item>
                </Fragment>
              );
            }) : (
              <DescriptionsStyled.Item className="no-label">
                {name}
                {' '}
                no cuenta con plataformas.
              </DescriptionsStyled.Item>
            )}
        </DescriptionsStyled>

        <DescriptionsStyled title={<SubtitleExpandedRowStyled>Pólizas</SubtitleExpandedRowStyled>} bordered size="small" layout="vertical">
          {Array.isArray(insurancePolicies) && insurancePolicies.length > 0
            ? insurancePolicies.filter((policy) => !policy.renew).map((policy) => {
              const { description, startDate, endDate } = policy;
              const days = daysBetweenDates(endDate, today);
              let showWarning = false;
              let colorIcon = '';

              if (days < 32) {
                showWarning = true;
                colorIcon = '#cc3300';
              } else if (days < 90) {
                showWarning = true;
                colorIcon = '#c69035';
              }
              return (
                <Fragment key={`${policy.id}-${record.name}`}>
                  <DescriptionsStyled.Item label="Descripción">
                    {showWarning && (
                      <Tooltip
                        title={() => (
                          <>
                            Restan
                            {' '}
                            <strong>{days}</strong>
                            {' '}
                            días de vigencia de la póliza.
                            {' '}
                            Renueva la póliza.
                          </>
                        )}
                        destroyTooltipOnHide
                        defaultOpen
                      >
                        <ExclamationCircleTwoTone
                          twoToneColor={colorIcon}
                          style={{
                            marginRight: 5,
                          }}
                        />
                      </Tooltip>
                    )}

                    {description}
                  </DescriptionsStyled.Item>
                  <DescriptionsStyled.Item label="Fecha inicial">
                    {dateFormat(startDate)}
                  </DescriptionsStyled.Item>
                  <DescriptionsStyled.Item label="Fecha final">
                    {dateFormat(endDate)}
                  </DescriptionsStyled.Item>
                </Fragment>
              );
            }) : (
              <DescriptionsStyled.Item className="no-label">
                {name}
                {' '}
                no cuenta con pólizas.
              </DescriptionsStyled.Item>
            )}
        </DescriptionsStyled>
      </>
    );
  }

  return render;
};
