import axiosConfig from 'Shared/Utils/axiosConfig';
import dayjs from 'dayjs';

function getScheduledTrucks(params = {}) {
  return axiosConfig.get('scheduled-trucks', { params })
    .then((response) => {
      let { data } = response;

      if (params.startDate && params.endDate) {
        data = data.assignTrucks.map((assignTruck) => {
          const title = `${assignTruck.status} ${assignTruck.truck.name} / ${assignTruck.driver.fullName}`;

          return {
            start: dayjs(assignTruck.dateAssigned).toDate(),
            end: dayjs(assignTruck.dateAssigned).toDate(),
            allDay: true,
            title,
          };
        });
      }
      return data;
    });
}

function getAvailableTrucks(dateAvailable, dateEndAvailable) {
  return axiosConfig.get('scheduled-trucks/status', {
    params: {
      date: dateAvailable,
      dateEnd: dateEndAvailable,
    },
  })
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function getAvailableDrivers(dateAvailable, dateEndAvailable) {
  return axiosConfig.get('scheduled-trucks/available-drivers', {
    params: {
      date: dateAvailable,
      dateEnd: dateEndAvailable,
    },
  })
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function createScheduledTruck(params) {
  return axiosConfig.post('scheduled-trucks', params)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function updateScheduledTruck(scheduledTruckId, params) {
  return axiosConfig.patch(`scheduled-trucks/${scheduledTruckId}`, params)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function removeScheduledTruck(scheduledTruckId) {
  return axiosConfig.delete(`scheduled-trucks/${scheduledTruckId}`)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

export const ScheduledTrucksAPI = {
  getScheduledTrucks,
  getAvailableTrucks,
  getAvailableDrivers,
  createScheduledTruck,
  updateScheduledTruck,
  removeScheduledTruck,
};
