import LogoColorIcon from 'Shared/Images/logo_icon.png';
import React from 'react';
import { MenuOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { Layout, Row, Col } from 'antd';

const { Header, Sider, Content } = Layout;

const SiderStyled = styled(Sider)`
  display: flex;
  align-items: center; 
  overflow: auto;
  min-height: calc(100vh - 64px);
  background: #001529!important;

  > .ant-layout-sider-children {
    width: 100%;
  }

  &.ant-layout-sider.ant-layout-sider-dark {
    position: absolute;
    top: 64px;
    z-index: 2;
  }
`;

const ContentStyled = styled(Content)`
  margin: 8px;
  padding: 16px;
  min-height: calc(100vh - 64px - 8px - 12px);
  background: white;
  overflow-y: auto;
`;

const Logo = styled.img`
  display: block;
  width: 30px;
  height: 30px;
`;

function LayoutMobile({
  Menu,
  MenuBottom,
  collapsed,
  setCollapsed,
}) {
  return (
    <Layout>
      <SiderStyled
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(!value)}
        collapsedWidth={0}
        trigger={null}
        width="100%"
      >
        {Menu}
        {MenuBottom}
      </SiderStyled>
      <Layout>
        <Header>
          <Row justify="space-between" align="middle">
            <Col>
              <Logo src={LogoColorIcon} />
            </Col>
            <Col>
              <MenuOutlined
                style={{ color: '#fff' }}
                onClick={() => setCollapsed(!collapsed)}
              />
            </Col>
          </Row>
        </Header>
        <ContentStyled>
          <Outlet />
        </ContentStyled>
      </Layout>
    </Layout>
  );
}

export default LayoutMobile;
