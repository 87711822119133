import axiosConfig from 'Shared/Utils/axiosConfig';

function getInsurances(params = {}) {
  return axiosConfig.get('insurance-policies', { params })
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function createInsurance(params) {
  return axiosConfig.post('insurance-policies', params)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function removeInsurance(insurancesId) {
  return axiosConfig.delete(`insurance-policies/${insurancesId}`)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

export const InsurancesAPI = {
  getInsurances,
  createInsurance,
  removeInsurance,
};
