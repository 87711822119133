import axiosConfig from 'Shared/Utils/axiosConfig';

function getUsers() {
  return axiosConfig.get('users')
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function removeUser(userId) {
  return axiosConfig.delete(`users/${userId}`)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

export const UsersAPI = {
  getUsers,
  removeUser,
};
