import { useRequestProcessor } from 'Shared/customHooks';
import FormItem from 'Shared/Components/Form/Item';
import { DriversAPI } from 'Shared/Services/drivers';
import {
  Row, Col, Button, Form, Input, notification,
} from 'antd';
import React, { useEffect } from 'react';

function CreateDrivers(props) {
  const {
    mobileDevice,
    onSuccess,
    typeAction,
    data,
  } = props;
  const { mutation } = useRequestProcessor();
  const [apiNotification, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const {
    isLoading: isLoadingCreate,
    mutate: mutateCreate,
  } = mutation('createDriver', (values) => DriversAPI.createDriver(values));

  const {
    isLoading: isLoadingUpdate,
    mutate: mutateUpdate,
  } = mutation('updateDriver', ({ id, values }) => DriversAPI.updateDriver(id, values));

  const onFinish = async (values) => {
    if (typeAction === 'create') {
      mutateCreate(values, {
        onSuccess: (response) => {
          const { message } = response;
          apiNotification.success({
            description: message,
          });
          form.resetFields();
          onSuccess();
        },
        onError: (error) => {
          const { data: info } = error.response;
          const { message } = info;
          apiNotification.error({
            description: message,
          });
        },
      });
    } else if (typeAction === 'update') {
      const { id } = data;
      mutateUpdate({
        id,
        values,
      }, {
        onSuccess: (response) => {
          const { message } = response;
          apiNotification.success({
            description: message,
          });
          form.resetFields();
          onSuccess();
        },
        onError: (error) => {
          const { data: info } = error.response;
          const { message } = info;
          apiNotification.error({
            description: message,
          });
        },
      });
    }
  };

  useEffect(() => {
    if (typeAction === 'create') form.resetFields();
    else form.setFieldsValue(data);
  }, [form, data, typeAction]);

  return (
    <>
      {contextHolder}
      <Form
        className="login-form"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={data}
      >
        <Row type="flex">
          <Col xs={24}>
            <FormItem
              label="Nombre completo"
              name="fullName"
              rules={[
                { required: true, message: 'Ingresa el nombre completo.' },
              ]}
            >
              <Input />
            </FormItem>
          </Col>
          <Col xs={24}>
            <FormItem
              label="Celular"
              name="phoneNumber"
              rules={[
                { required: true, message: 'Ingresa un número de celular.' },
                {
                  required: true,
                  pattern: /^[0-9]*$/,
                  message:
                    'Debes ingresar solo numeros.',
                },
                {
                  required: true,
                  max: 10,
                  message:
                    'Debes ingresar máximo 10 números.',
                },
              ]}
            >
              <Input />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <Col>
            <Button
              type="primary"
              className="bg-blue-800"
              loading={isLoadingCreate || isLoadingUpdate}
              htmlType="submit"
              block={mobileDevice}
              style={{ marginTop: '1.3rem' }}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default CreateDrivers;
