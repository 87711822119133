import { useRequestProcessor } from 'Shared/customHooks';
import { PlatformsAPI } from 'Shared/Services/platforms';
import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const SelectPlatformTrucks = React.forwardRef(({ ...props }, ref) => {
  const { style, disabled, filter } = props;
  const { query } = useRequestProcessor();

  const { data, isLoading } = query(
    'selectPlatformTrucks',
    () => PlatformsAPI.getPlatforms(),
    { enabled: true },
  );

  return (
    <Select
      {...props}
      ref={ref}
      style={{
        ...style,
        width: style && style.width ? style.width : '100%',
      }}
      disabled={disabled}
      loading={isLoading}
    >
      {data && data.platformTrucks.map((platformTruck) => (
        <Option key={platformTruck.id} value={platformTruck.id} filter={filter}>
          {`(${platformTruck.reference}) ${platformTruck.name}`}
        </Option>
      ))}
    </Select>
  );
});

export default SelectPlatformTrucks;
