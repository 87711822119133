import { useRequestProcessor } from 'Shared/customHooks';
import { ClientsAPI } from 'Shared/Services/clients';
import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const filterOption = (input, option) => (option?.children ?? '').toLowerCase().includes(input.toLowerCase());

const SelectClients = React.forwardRef(({ ...props }, ref) => {
  const { style, disabled, filter } = props;
  const { query } = useRequestProcessor();

  const { data, isLoading } = query(
    'selectClients',
    () => ClientsAPI.getClients(),
    { enabled: true },
  );

  return (
    <Select
      {...props}
      ref={ref}
      style={{
        ...style,
        width: style && style.width ? style.width : '100%',
      }}
      showSearch
      optionFilterProp="children"
      disabled={disabled}
      loading={isLoading}
      filterOption={filterOption}
    >
      {data && data.clients.map((client) => (
        <Option key={client.id} value={client.id} filter={filter}>
          {`${client.fullName} - ${client.rfc}`}
        </Option>
      ))}
    </Select>
  );
});

export default SelectClients;
