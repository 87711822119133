import Actions from 'Shared/Components/Actions';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import React from 'react';

const items = (handleActions, record) => [
  {
    label: 'Inactivar',
    key: 'inactive',
    icon: <DeleteFilled style={{ color: '#D11A2A' }} />,
    onClick: (item) => {
      handleActions(item, record);
    },
  },
  {
    label: 'Actualizar',
    key: 'update',
    icon: <EditFilled style={{ color: '#AD2AA5' }} />,
    onClick: (item) => {
      handleActions(item, record);
    },
  },
];

export const columns = (handleActions) => [
  {
    title: 'Nombre',
    dataIndex: 'fullName',
    sorter: (a, b) => a.fullName.length - b.fullName.length,
  },
  {

    title: 'Teléfono',
    dataIndex: 'phone',
    sorter: (a, b) => a.phone.length - b.phone.length,
  },
  {

    title: 'Correo electrónico',
    dataIndex: 'email',
    sorter: (a, b) => a.email.length - b.email.length,
  },
  {

    title: 'RFC',
    dataIndex: 'rfc',
    sorter: (a, b) => a.rfc.length - b.rfc.length,
  },
  {
    key: 'action',
    width: 1,
    render: (record) => (
      <Actions
        menu={{
          items: items(handleActions, record),
        }}
        placement="bottomRight"
      />
    ),
  },
];
