import CreateEmployees from 'Contacts/Employees/Actions/Create';
import DeleteEmployees from 'Contacts/Employees/Actions/Delete';
import React from 'react';

const ActionsUsers = (props) => {
  const {
    action, mobileDevice, onSuccess, data,
  } = props;

  const renderAction = (typeAction) => {
    let actionRender = null;
    if (typeAction === 'create') {
      actionRender = <CreateEmployees mobileDevice={mobileDevice} onSuccess={onSuccess} />;
    } else if (typeAction === 'inactive') {
      actionRender = (
        <DeleteEmployees
          data={data}
          mobileDevice={mobileDevice}
          onSuccess={onSuccess}
        />
      );
    }

    return actionRender;
  };

  return renderAction(action);
};

export default ActionsUsers;
