import PageHeader from 'Shared/Components/PageHeader';
import { useWindowWidthSize, useRequestProcessor } from 'Shared/customHooks';
import Loader from 'Shared/Components/Loader';
import ModalStyled from 'Shared/Components/Modal';
import TitleModal from 'Shared/Components/TitleModal';
import TableStyled from 'Shared/Components/Table';
import ActionsInsurances from 'Insurances/Actions';
import { daysBetweenDates } from 'Shared/Utils';
import { InsurancesAPI } from 'Shared/Services/insurances';
import { SafetyCertificateOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Button,
} from 'antd';
import React, { useState, useEffect } from 'react';
// import InsurancesTransportation from './Transportation';
import FilterInsurances from './filter';
import { columns } from './utils';

function Insurances() {
  const { query } = useRequestProcessor();
  const {
    widthDeviceSize,
    isMobile,
  } = useWindowWidthSize();
  const [modalState, setModalState] = useState({
    visibleModal: false,
    title: '',
    action: null,
    data: null,
  });
  const [responsiveDesign, setResponsiveDesign] = useState(false);
  const [queryParams, setQueryParams] = useState({});

  const { data, isLoading } = query(
    'insurances',
    () => InsurancesAPI.getInsurances(queryParams),
    { enabled: true },
    queryParams,
  );

  const toggleModal = () => {
    setModalState((currentState) => ({
      ...currentState,
      visibleModal: !currentState.visibleModal,
    }));
  };

  const onSuccess = () => {
    setModalState((currentState) => ({
      title: '',
      action: null,
      visibleModal: !currentState.visibleModal,
    }));
  };

  const handleOnClickCreateInsurance = () => {
    toggleModal();
    setModalState((currentState) => ({
      ...currentState,
      title: <TitleModal Icon={SafetyCertificateOutlined} title="Crear póliza" />,
      action: 'create',
      data: null,
    }));
  };

  const handleActions = (menuItemClicked, record) => {
    const { key } = menuItemClicked;

    if (key === 'renew') {
      setModalState(() => ({
        visibleModal: true,
        title: <TitleModal Icon={SafetyCertificateOutlined} title="Renovar póliza" />,
        action: 'renew',
        data: record,
      }));
    }
  };

  const onSubmit = (values) => {
    const { renewed } = values;
    let formatRenewed;
    if (renewed) formatRenewed = 1;
    else formatRenewed = 0;

    setQueryParams({
      ...values,
      renewed: formatRenewed,
    });
  };

  useEffect(() => {
    if (widthDeviceSize <= 1024) setResponsiveDesign(true);
    else setResponsiveDesign(false);
  }, [widthDeviceSize]);

  return (
    <>
      <PageHeader title="Pólizas" />

      <Row justify="end">
        <Col xs={24} lg={24}>
          <Button
            type="primary"
            className="bg-blue-800"
            block={isMobile}
            style={{
              float: 'right',
              marginBottom: '2rem',
              marginLeft: '0.5rem',
            }}
            onClick={handleOnClickCreateInsurance}
          >
            Crear póliza
          </Button>
          <FilterInsurances onSubmit={onSubmit} />
        </Col>
      </Row>

      {/* <InsurancesTransportation /> */}

      <Row>
        <Col xs={24}>
          { isLoading
            ? <Loader />
            : (
              <TableStyled
                size="middle"
                columns={columns(handleActions)}
                dataSource={data.insurancePolicies}
                rowKey={(record) => record.id}
                rowClassName={(record) => {
                  const today = new Date();
                  const days = daysBetweenDates(record.endDate, today);
                  let stringClassName = '';

                  if (days < 32 && !record.renew) {
                    stringClassName = 'warning';
                  } else if (days < 90 && !record.renew) {
                    stringClassName = 'caution';
                  }

                  return stringClassName;
                }}
                pagination={{
                  defaultCurrent: 1,
                  total: data.total,
                }}
                scroll={{ x: responsiveDesign }}
              />
            )}
        </Col>
      </Row>

      <ModalStyled
        forceRender
        getContainer={false}
        open={modalState.visibleModal}
        centered
        title={modalState.title}
        onCancel={toggleModal}
        footer={null}
      >
        <ActionsInsurances
          action={modalState.action}
          onSuccess={onSuccess}
          mobileDevice={isMobile}
          data={modalState.data}
        />
      </ModalStyled>
    </>
  );
}

export default Insurances;
