import styled from 'styled-components';
import { Link } from 'react-router-dom';

const LinkStyled = styled(Link)`
  display: block;
  text-align: center;
  margin-top: 10px;
  font-size: 13px;

  :hover {
    color: #003EA0;
  }
`;

export default LinkStyled;
