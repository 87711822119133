import axiosConfig from 'Shared/Utils/axiosConfig';

function getRequestedServices(params = {}) {
  return axiosConfig.get('services', { params })
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function createRequestedServices(params) {
  return axiosConfig.post('services', params)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function updateRequestedServices(serviceId, params) {
  return axiosConfig.patch(`services/${serviceId}`, params)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function getRequestedService(serviceId) {
  return axiosConfig.get(`services/${serviceId}`)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function getNumRequisition(numRequisition) {
  return axiosConfig.get(`requisition/${numRequisition}`)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function removeRequestedServices(serviceId) {
  return axiosConfig.delete(`services/${serviceId}`)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

export const RequestedServicesAPI = {
  getRequestedServices,
  getRequestedService,
  getNumRequisition,
  createRequestedServices,
  updateRequestedServices,
  removeRequestedServices,
};
