import Logo from 'Shared/Images/logo.png';
import TrucksImage from 'Shared/Images/trucks_moc_2.jpeg';
import { useAuth } from 'Shared/Providers/Auth';
import LinkStyled from 'Shared/Components/Link';
import FormItem from 'Shared/Components/Form/Item';
import React from 'react';
import {
  Layout, Form, Input, Button,
  Row, Col, Alert, notification,
} from 'antd';
import styled from 'styled-components';

// eslint-disable-next-line import/no-extraneous-dependencies
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Password } = Input;

const LayoutStyled = styled(Layout)`
  min-height: 100vh;
  background-color: #FFF;
  background-repeat: no-repeat;
  background-size: cover;
`;

const ContentStyled = styled(Content)`
  display:flex;
  justify-content:center;
  align-items:center;
  width:100%;
`;

const LogoStyled = styled.img`
  display: block;
  margin: 0 auto 50px;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
  height: 160px;
`;

const BackgroundImage = styled.div`
  background-image: url(${TrucksImage});
  background-size: cover;
  background-position: center center;
  min-height: 100%;
  height: 100vh;
`;

function Login() {
  const { login } = useAuth();
  const [apiNotification, contextHolder] = notification.useNotification();
  const onFinish = async (values) => {
    const { username, password } = values;
    const res = await login({ username, password });

    apiNotification.warning({
      description: res.message,
      placement: 'bottomRight',
    });
  };

  return (
    <LayoutStyled>
      <Row justify="space-around" align="middle">
        <Col xs={0} md={12}>
          <BackgroundImage />
        </Col>
        <Col md={12}>
          <LayoutStyled>
            {contextHolder}
            <ContentStyled>
              <Form layout="vertical" onFinish={onFinish} className="login-form" colon={false} hideRequiredMark>
                {
                  localStorage.getItem('sessionExpired') === '1' && (
                    <Row justify="center">
                      <Col xs={24}>
                        <Alert
                          message="La sesión ha expirado por seguridad."
                          type="info"
                          showIcon
                          closable
                          style={{
                            marginBottom: '2rem',
                          }}
                        />
                      </Col>
                    </Row>
                  )
                }
                <Row justify="center">
                  <Col xs={24}>
                    <LogoStyled src={Logo} alt="Logotipo Grupo MOC" />
                  </Col>
                </Row>
                <Row justify="center">
                  <Col xs={24}>
                    <FormItem
                      label="Correo electrónico"
                      name="username"
                    >
                      <Input
                        prefix={<UserOutlined />}
                        placeholder="Correo electrónico"
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col xs={24}>
                    <FormItem label="Contraseña" name="password">
                      <Password
                        prefix={<LockOutlined />}
                        placeholder="Contraseña"
                      />
                    </FormItem>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col xs={24}>
                    <Button
                      type="primary"
                      className="bg-blue-800"
                      htmlType="submit"
                      block
                    >
                      Acceder
                    </Button>
                  </Col>
                </Row>

                <Row justify="center">
                  <Col xs={24}>
                    <LinkStyled
                      to="/reset-password"
                    >
                      ¿Olvidaste tu contraseña?
                    </LinkStyled>
                  </Col>
                </Row>
              </Form>
            </ContentStyled>
          </LayoutStyled>
        </Col>
      </Row>
    </LayoutStyled>
  );
}

export default Login;
