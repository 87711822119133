import DashboardLayout from 'Layouts/Dashboard';
import MainLayout from 'Layouts/MainLayout';
import {
  Clients,
  Vendors,
  Employees,
  Drivers,
} from 'Contacts';
import {
  Trucks,
  Platforms,
  ScheduledTrucks,
} from 'Transportation';
import Insurances from 'Insurances';
import {
  Expenses,
} from 'Finance';
import Location from 'Location';
import Purchases from 'Services/Request';
import ViewClientService from 'Services/ViewClient';
import ViewInformationService from 'Services/ViewClient/Information';
import CreateService from 'Services/Schedule';
import PDFDocument from 'Services/Download';
import { AuthProvider, RequireAuth } from 'Shared/Providers/Auth';
import Login from 'Login';
import { ConfigProvider } from 'antd';
import { Route, Routes } from 'react-router-dom';
import React from 'react';

function App() {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: '#003EA0',
            colorPrimaryHover: '#1D1D1B',
            colorPrimaryBorder: '#003EA0',
            colorPrimaryActive: '#1D1D1B',
          },
          Pagination: {
            colorPrimary: '#003EA0',
            colorPrimaryHover: '#1D1D1B',
            colorPrimaryBorder: '#003EA0',
            colorPrimaryActive: '#1D1D1B',
          },
          Descriptions: {
            labelBg: '#1D1D1B',
          },
        },
      }}
    >
      <AuthProvider>
        <Routes>
          <Route element={<MainLayout />}>
            <Route
              path="/dashboard"
              element={
                (
                  <RequireAuth>
                    <DashboardLayout />
                  </RequireAuth>
                )
              }
            />
            <Route
              path="/clients"
              element={
                (
                  <RequireAuth>
                    <Clients />
                  </RequireAuth>
                )
              }
            />
            <Route
              path="/drivers"
              element={
                (
                  <RequireAuth>
                    <Drivers />
                  </RequireAuth>
                )
              }
            />
            <Route
              path="/vendors"
              element={
                (
                  <RequireAuth>
                    <Vendors />
                  </RequireAuth>
                )
              }
            />
            <Route
              path="/employees"
              element={
                (
                  <RequireAuth>
                    <Employees />
                  </RequireAuth>
                )
              }
            />
            <Route
              path="/trucks"
              element={
                (
                  <RequireAuth>
                    <Trucks />
                  </RequireAuth>
                )
              }
            />
            <Route
              path="/platforms"
              element={
                (
                  <RequireAuth>
                    <Platforms />
                  </RequireAuth>
                )
              }
            />
            <Route
              path="/calendar"
              element={
                (
                  <RequireAuth>
                    <ScheduledTrucks />
                  </RequireAuth>
                )
              }
            />
            <Route
              path="/insurances"
              element={
                (
                  <RequireAuth>
                    <Insurances />
                  </RequireAuth>
                )
              }
            />
            <Route
              path="/expenses"
              element={
                (
                  <RequireAuth>
                    <Expenses />
                  </RequireAuth>
                )
              }
            />
            <Route
              path="/request-service"
              element={
                (
                  <RequireAuth>
                    <Purchases />
                  </RequireAuth>
                )
              }
            />
            <Route
              path="/schedule-service"
              element={
                (
                  <RequireAuth>
                    <CreateService />
                  </RequireAuth>
                )
              }
            />
            <Route
              path="/in-service"
              element={
                (
                  <RequireAuth>
                    <CreateService />
                  </RequireAuth>
                )
              }
            />
            <Route
              path="/end-service"
              element={
                (
                  <RequireAuth>
                    <CreateService />
                  </RequireAuth>
                )
              }
            />
            <Route
              path="/location"
              element={<Location />}
            />
          </Route>

          <Route path="services" element={<MainLayout finalUser />}>
            <Route
              path="information"
              element={
                (
                  <ViewInformationService />
                )
              }
            />
            <Route
              index
              element={
                (
                  <ViewClientService />
                )
              }
            />
          </Route>

          <Route path="/" element={<Login />} />
          <Route
            path="/request-service/download/:requestedId"
            element={
              (
                <RequireAuth>
                  <PDFDocument />
                </RequireAuth>
              )
            }
          />
        </Routes>
      </AuthProvider>
    </ConfigProvider>
  );
}

export default App;
