import {
  List,
  Tooltip,
} from 'antd';
import React from 'react';
import styled from 'styled-components';

const DayTitle = styled.strong`
  display: block;
  font-size: 0.7rem;
  margin-top: 2.1rem;
  padding: 0 22px;

  @media (max-width: 425px) {
    margin-top: 0;
  }
`;

const ListItemContainer = styled.div`
  display: flex;

  &:hover {
    background-color: #F8F8FA;
  }
`;

const ListItemDescription = styled.div`
  padding-left: 10px;
`;

const ListItemTitle = styled.strong`
  display: block;
  color: #666666;
`;

const ListItemStatus = styled.div`
  width: 10px;
  ${(props) => props.status === 'Assigned' && `
    background-color: green;
  `}
  ${(props) => props.status === 'Scheduled' && `
    background-color: #EED202;
  `}
  ${(props) => props.status === 'Workshop' && `
    background-color: red;
  `}
`;

function StatusSpanish(status) {
  let statusString = '';
  if (status === 'Assigned') {
    statusString = 'Asignado';
  } else if (status === 'Scheduled') {
    statusString = 'Programado';
  } else if (status === 'Workshop') {
    statusString = 'En taller';
  }

  return statusString;
}

function ListRender({
  status,
  truck,
  driver,
}) {
  const { name, tag, ecoNumber } = truck;
  const { fullName } = driver;

  return (
    <Tooltip title={StatusSpanish(status)}>
      <ListItemContainer>
        <ListItemStatus status={status} />
        <ListItemDescription>
          <ListItemTitle>{`${name} ${ecoNumber} ${tag}`}</ListItemTitle>
          <span>{fullName}</span>
        </ListItemDescription>
      </ListItemContainer>
    </Tooltip>
  );
}

function ListScheduledTrucks({ dataList }) {
  return (
    <List
      itemLayout="horizontal"
      dataSource={dataList}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            description={ListRender(item)}
          />
        </List.Item>
      )}
    />
  );
}

export default ListRender;

export {
  ListRender,
  ListScheduledTrucks,
  DayTitle,
};
