import { useRequestProcessor } from 'Shared/customHooks';
import FormItem from 'Shared/Components/Form/Item';
import SelectTrucks from 'Shared/Selects/Trucks';
import SelectDrivers from 'Shared/Selects/Drivers';
import { ScheduledTrucksAPI } from 'Shared/Services/scheduledTrucks';
import {
  Row, Col, Button, Form, notification, DatePicker,
} from 'antd';
import React from 'react';
import dayjs from 'dayjs';

function CreateScheduled(props) {
  const { mobileDevice, onSuccess } = props;
  const { mutation } = useRequestProcessor();
  const [apiNotification, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const {
    isLoading,
    mutate,
  } = mutation('createScheduledTruck', (values) => ScheduledTrucksAPI.createScheduledTruck(values));

  const onFinish = async (values) => {
    const formatDate = dayjs(values.date).format('YYYY-MM-DD');

    const formatValues = {
      ...values,
      date: formatDate,
    };

    mutate(formatValues, {
      onSuccess: (data) => {
        const { message } = data;
        apiNotification.success({
          description: message,
        });
        form.resetFields();
        onSuccess();
      },
      onError: (error) => {
        const { data } = error.response;
        const { message } = data;
        apiNotification.error({
          description: message,
        });
      },
    });
  };

  return (
    <>
      {contextHolder}
      <Form
        className="login-form"
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Row type="flex">
          <Col xs={24}>
            <FormItem
              label="Fecha de asignación"
              name="date"
              rules={[
                { required: true, message: 'Ingresa la fecha de la asignación' },
              ]}
            >
              <DatePicker placeholder="Fecha de asignación" format="DD/MM/YYYY" />
            </FormItem>
          </Col>
          <Col xs={24}>
            <FormItem
              name="truck_id"
              label="Selecciona una unidad"
              rules={[{ required: true, message: 'Selecciona una unidad' }]}
            >
              <SelectTrucks />
            </FormItem>
          </Col>
          <Col xs={24}>
            <FormItem
              name="driver_id"
              label="Selecciona un operador"
              rules={[{ required: true, message: 'Selecciona un operador' }]}
            >
              <SelectDrivers />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <Col>
            <Button
              type="primary"
              className="bg-blue-800"
              loading={isLoading}
              htmlType="submit"
              block={mobileDevice}
              style={{ marginTop: '1.3rem' }}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default CreateScheduled;
