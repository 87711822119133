import PageHeader from 'Shared/Components/PageHeader';
import { useWindowWidthSize, useRequestProcessor } from 'Shared/customHooks';
import ModalStyled from 'Shared/Components/Modal';
import TableStyled from 'Shared/Components/Table';
import Loader from 'Shared/Components/Loader';
import TitleModal from 'Shared/Components/TitleModal';
import ActionsClients from 'Contacts/Clients/Actions';
import { ClientsAPI } from 'Shared/Services/clients';
import { UserOutlined, EditOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { columns } from './utils';

function Clients() {
  const { query } = useRequestProcessor();
  const {
    widthDeviceSize,
    isMobile,
  } = useWindowWidthSize();
  const [modalState, setModalState] = useState({
    visibleModal: false,
    title: '',
    action: null,
    data: null,
  });
  const [responsiveDesign, setResponsiveDesign] = useState(false);

  const { data, isLoading } = query(
    'clients',
    () => ClientsAPI.getClients(),
    { enabled: true },
  );

  const toggleModal = () => {
    setModalState((currentState) => ({
      ...currentState,
      visibleModal: !currentState.visibleModal,
    }));
  };

  const onSuccess = () => {
    setModalState((currentState) => ({
      title: '',
      action: null,
      visibleModal: !currentState.visibleModal,
    }));
  };

  const handleOnClickCreateClients = () => {
    toggleModal();
    setModalState((currentState) => ({
      ...currentState,
      data: null,
      title: <TitleModal Icon={UserOutlined} title="Crear cliente" />,
      action: 'create',
    }));
  };

  const handleActions = (menuItemClicked, record) => {
    const { key } = menuItemClicked;

    if (key === 'inactive') {
      setModalState(() => ({
        visibleModal: true,
        title: <TitleModal Icon={UserOutlined} title="Eliminar cliente" />,
        action: 'inactive',
        data: record,
      }));
    } else if (key === 'update') {
      setModalState(() => ({
        visibleModal: true,
        title: <TitleModal Icon={EditOutlined} title="Actualizar cliente" />,
        action: 'update',
        data: record,
      }));
    }
  };

  useEffect(() => {
    if (widthDeviceSize <= 1024) setResponsiveDesign(true);
    else setResponsiveDesign(false);
  }, [widthDeviceSize]);

  return (
    <>
      <PageHeader title="Clientes" />

      <Row justify="end">
        <Col xs={24} lg={24}>
          <Button
            type="primary"
            className="bg-blue-800"
            block={isMobile}
            style={{
              float: 'right',
              marginBottom: '2rem',
            }}
            onClick={handleOnClickCreateClients}
          >
            Crear cliente
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          { isLoading
            ? <Loader />
            : (
              <TableStyled
                size="middle"
                columns={columns(handleActions)}
                dataSource={data.clients}
                rowKey={(record) => record.id}
                pagination={{
                  defaultCurrent: 1,
                  total: data.total,
                }}
                scroll={{ x: responsiveDesign }}
              />
            )}
        </Col>
      </Row>
      <ModalStyled
        open={modalState.visibleModal}
        centered
        title={modalState.title}
        onCancel={toggleModal}
        footer={null}
      >
        <ActionsClients
          action={modalState.action}
          onSuccess={onSuccess}
          mobileDevice={isMobile}
          data={modalState.data}
        />
      </ModalStyled>
    </>
  );
}

export default Clients;
