import axiosConfig from 'Shared/Utils/axiosConfig';

function getTrucks(params = {}) {
  return axiosConfig.get('trucks', { params })
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function createTruck(params) {
  return axiosConfig.post('trucks', params)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function updateTruck(truckId, params) {
  return axiosConfig.patch(`trucks/${truckId}`, params)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function removeTruck(truckId) {
  return axiosConfig.delete(`trucks/${truckId}`)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

export const TrucksAPI = {
  getTrucks,
  createTruck,
  updateTruck,
  removeTruck,
};
