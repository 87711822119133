import styled from 'styled-components';
import { DatePicker } from 'antd';

const DatePickerStyled = styled(DatePicker)`
  width: 100%;
`;

DatePickerStyled.defaultProps = {
};

export default DatePickerStyled;
