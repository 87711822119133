import { useWindowWidthSize } from 'Shared/customHooks';
import React, { useState, useEffect } from 'react';
import { MenuOwn, MenuBottomOwn } from './Menus';
import LayoutMobile from './LayoutMobile';
import LayoutDesktop from './LayoutDesktop';

function MainLayout({ finalUser }) {
  const {
    widthDeviceSize,
    isMobile,
  } = useWindowWidthSize(767);
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    if (widthDeviceSize <= 1024) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [widthDeviceSize]);

  if (!isMobile) {
    return (
      <LayoutDesktop
        Menu={<MenuOwn mobile={+false} menuClient={finalUser} />}
        MenuBottom={<MenuBottomOwn mobile={+false} menuClient={finalUser} />}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />
    );
  }
  return (
    <LayoutMobile
      Menu={
        (
          <MenuOwn
            mobile={+true}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            menuClient={finalUser}
          />
        )
      }
      MenuBottom={<MenuBottomOwn mobile={+true} menuClient={finalUser} />}
      collapsed={collapsed}
      setCollapsed={setCollapsed}
    />
  );
}

export default MainLayout;
