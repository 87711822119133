import { useRequestProcessor } from 'Shared/customHooks';
import { DriversAPI } from 'Shared/Services/drivers';
import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const SelectDrivers = React.forwardRef(({ ...props }, ref) => {
  const { style, disabled, filter } = props;
  const { query } = useRequestProcessor();

  const { data, isLoading } = query(
    'selectDrivers',
    () => DriversAPI.getDrivers(),
    { enabled: true },
  );

  return (
    <Select
      {...props}
      ref={ref}
      style={{
        ...style,
        width: style && style.width ? style.width : '100%',
      }}
      disabled={disabled}
      loading={isLoading}
    >
      {data && data.drivers.map((driver) => (
        <Option key={driver.id} value={driver.id} filter={filter}>
          {driver.fullName}
        </Option>
      ))}
    </Select>
  );
});

export default SelectDrivers;
