import { useRequestProcessor } from 'Shared/customHooks';
import FormItem from 'Shared/Components/Form/Item';
import { InsurancesAPI } from 'Shared/Services/insurances';
import DatePickerStyled from 'Shared/Components/DatePicker';
import SelectPlatformTrucks from 'Shared/Selects/PlatformTrucks';
import SelectTrucks from 'Shared/Selects/Trucks';
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  notification,
  Radio,
  InputNumber,
} from 'antd';
import React from 'react';

function CreateInsurances(props) {
  const {
    mobileDevice,
    onSuccess,
  } = props;
  const { mutation } = useRequestProcessor();
  const [apiNotification, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const start = Form.useWatch('startDate', form);
  const kindTransport = Form.useWatch('transport', form);

  const {
    isLoading,
    mutate,
  } = mutation('register', (values) => InsurancesAPI.createInsurance(values));

  const onFinish = async (values) => {
    values.renew = 0;
    values.startDate = values.startDate.format('YYYY-MM-DD HH:mm:ss');
    values.endDate = values.endDate.format('YYYY-MM-DD HH:mm:ss');
    mutate(values, {
      onSuccess: (data) => {
        const { message } = data;
        apiNotification.success({
          description: message,
        });
        form.resetFields();
        onSuccess();
      },
      onError: (error) => {
        const { data } = error.response;
        const { message } = data;
        apiNotification.error({
          description: message,
        });
      },
    });
  };

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        className="login-form"
        layout="vertical"
        onFinish={onFinish}
      >
        <Row type="flex" gutter={20}>
          <Col xs={24} md={12}>
            <FormItem
              label="Fecha inicial"
              name="startDate"
              rules={[{ required: true, message: 'Selecciona una fecha inicial de la vigencia' }]}
            >
              <DatePickerStyled
                placeholder="Selecciona la fecha inicial"
                onChange={(date) => date.format('YYYY-MM-DD HH:mm:ss')}
              />
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem
              label="Fecha final"
              name="endDate"
              rules={[{ required: true, message: 'Selecciona una fecha final de la vigencia' }]}
            >
              <DatePickerStyled
                placeholder="Selecciona la fecha final"
                disabled={!start}
                disabledDate={(current) => current && form.getFieldValue('startDate') > current}
              />
            </FormItem>
          </Col>
          <Col xs={24}>
            <FormItem
              label="Costo"
              name="price"
              rules={[
                { required: true, message: 'Ingresa el costo de la póliza.' },
              ]}
            >
              <InputNumber
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                className="w-full"
              />
            </FormItem>
          </Col>
          <Col xs={24}>
            <FormItem
              label="Descripción de la póliza"
              name="description"
              rules={[{ required: true, message: 'Ingresa una descripción de la póliza' }]}
            >
              <Input.TextArea
                autoSize={{
                  minRows: 3,
                  maxRows: 3,
                }}
              />
            </FormItem>
          </Col>
          <Col xs={24}>
            <FormItem
              name="transport"
              label="Selecciona tu tipo de póliza"
              rules={[{ required: true, message: 'Ingresa tu tipo de póliza' }]}
            >
              <Radio.Group>
                <Radio value={1}>Unidad</Radio>
                <Radio value={2}>Plataforma</Radio>
                <Radio value={3}>Otro</Radio>
              </Radio.Group>
            </FormItem>
          </Col>

          {kindTransport === 1 && (
            <Col xs={24}>
              <FormItem
                name="truckId"
                label="Selecciona una unidad"
                rules={[{ required: true, message: 'Selecciona una unidad' }]}
              >
                <SelectTrucks />
              </FormItem>
            </Col>
          )}
          {kindTransport === 2 && (
            <Col xs={24}>
              <FormItem
                name="platformTruckId"
                label="Selecciona una plataforma"
                rules={[{ required: true, message: 'Selecciona una plataforma' }]}
              >
                <SelectPlatformTrucks />
              </FormItem>
            </Col>
          )}
        </Row>
        <Row type="flex" justify="end">
          <Col>
            <Button
              type="primary"
              className="bg-blue-800"
              loading={isLoading}
              htmlType="submit"
              block={mobileDevice}
              style={{ marginTop: '1.3rem' }}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default CreateInsurances;
