import { Form } from 'antd';
import styled from 'styled-components';
import React from 'react';

const { Item } = Form;

const ItemStyled = styled(Item)`
  margin-bottom: 16px;

  .ant-form-item-label {
    display: flex;
    padding-bottom: 0.15rem;

    & > label {
      display: flex;
      flex-direction: row-reverse;
      flex-basis: fit-content;
      font-family: 'Plus Jakarta Sans', sans-serif !important;
      
    }

    & >label .ant-form-item-tooltip {
      margin-right: 5px;
      float: right;
    }

    & > label,
    & >label.ant-form-item-required::before {
      color: #1D1D1B !important;
      font-weight: bold;
      font-size: 12px !important;
      margin-inline-end: 0!important;
    }

    & >label.ant-form-item-required::after {
      display: none;
    } 
  }

  .ant-form-item-control-input {
    & > .ant-form-item-control-input-content {
      & > input.ant-input:focus,
      & > input.ant-input:hover {
        border-color: #003EA0 !important;
      }

      & .ant-input-affix-wrapper:focus,
      & .ant-input-affix-wrapper-focused {
        border-color: #003EA0 !important;
      }

      & .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
        border-color: #003EA0 !important;
      }

      & .ant-select-focused:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
        border-color: #003EA0 !important;
      }

      & .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: #003EA0 !important;
      }
    }
  }

  .ant-form-item-explain {
    margin-top: 0.3rem;
  }

  .ant-form-item-explain-error {
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 12px;
    font-weight: bold;
  }

  .ant-form-item-explain-error::before {
    display: inline-block;
    margin-inline-start: 5px;
    margin-inline-end: 3px;
    color: #ff4d4f;
    line-height: 1;
    content: "*";
    vertical-align: text-bottom;
  }

  .ant-picker {
    width: 100%;
  }
`;

function FormItem(props) {
  const { children } = props;
  return (
    <ItemStyled {...props}>
      {children}
    </ItemStyled>
  );
}

export default FormItem;
