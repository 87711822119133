import styled from 'styled-components';
import { Modal as ModalAntd } from 'antd';
import React from 'react';

const ModalStyled = styled(ModalAntd)`
  .ant-modal-header {
    border-bottom: 1px solid transparent;
    margin-bottom: 1.5rem;
  }

  .ant-modal-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: bolder;
    color: #1D1D1B;

    @media (max-width: 425px){
      font-size: 1rem;
    }
  }
`;

function Modal(props) {
  const { children } = props;
  return (
    <ModalStyled centered {...props}>
      {children}
    </ModalStyled>
  );
}

Modal.defaultProps = {
  backgroundColor: 'transparent',
};

export default Modal;
