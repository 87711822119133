import { useRequestProcessor } from 'Shared/customHooks';
import { ScheduledTrucksAPI } from 'Shared/Services/scheduledTrucks';
import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const SelectAvailableDrivers = React.forwardRef(({ ...props }, ref) => {
  const {
    style,
    disabled,
    date: dateAvailable,
    dateEnd: dateEndAvailable,
    preload,
  } = props;
  const { query } = useRequestProcessor();

  const { data, isLoading } = query(
    ['selectAvailableDrivers', dateAvailable, dateEndAvailable],
    () => ScheduledTrucksAPI.getAvailableDrivers(dateAvailable, dateEndAvailable),
    { enabled: !disabled },
  );

  return (
    <Select
      {...props}
      ref={ref}
      style={{
        ...style,
        width: style && style.width ? style.width : '100%',
      }}
      disabled={disabled}
      loading={isLoading}
    >
      {preload && (
        <Option key={preload.id} value={preload.id}>
          {preload.fullName}
        </Option>
      )}
      {data && data.availableDrivers.map((driver) => (
        <Option key={driver.id} value={driver.id}>
          {driver.fullName}
        </Option>
      ))}
    </Select>
  );
});

export default SelectAvailableDrivers;
