import SelectUserRoles from 'Shared/Selects/UserRoles';
import { useRequestProcessor } from 'Shared/customHooks';
import randomPassword from 'Shared/Utils/randomPassword';
import FormItem from 'Shared/Components/Form/Item';
import { AuthAPI } from 'Shared/Services/auth';
import {
  Row, Col, Button, Form, Input, notification,
} from 'antd';
import React, { useState } from 'react';

function CreateEmployees(props) {
  const { mobileDevice, onSuccess } = props;
  const { mutation } = useRequestProcessor();
  const [apiNotification, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);

  const {
    isLoading,
    mutate,
  } = mutation('register', (values) => AuthAPI.register(values));

  const onFinish = async (values) => {
    mutate(values, {
      onSuccess: (data) => {
        const { message } = data;
        apiNotification.success({
          description: message,
        });
        form.resetFields();
        onSuccess();
      },
      onError: (error) => {
        const { data } = error.response;
        const { message } = data;
        apiNotification.error({
          description: message,
        });
      },
    });
  };

  const handleRandomPassword = () => {
    const password = randomPassword(10, 'medium');
    setPasswordVisible(true);
    setPasswordConfirmVisible(true);
    form.setFieldsValue({
      password,
      confirmPassword: password,
    });
  };

  return (
    <>
      {contextHolder}
      <Form
        className="login-form"
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Row type="flex">
          <Col xs={24}>
            <FormItem
              label="Nombre completo"
              name="fullName"
              rules={[
                { required: true, message: 'Ingresa el nombre completo' },
              ]}
            >
              <Input />
            </FormItem>
          </Col>
          <Col xs={24}>
            <FormItem
              label="Correo electrónico"
              name="email"
              rules={[
                { required: true, message: 'Ingresa un correo electrónico' },
                { type: 'email', message: 'Ingresa un correo electrónico valido.' },
              ]}
            >
              <Input />
            </FormItem>
          </Col>
          <Col xs={24}>
            <FormItem
              label="Rol del usuario"
              name="rolId"
              rules={[{ required: true, message: 'Selecciona un rol' }]}
            >
              <SelectUserRoles
                placeholder="Selecciona un rol"
              />
            </FormItem>
          </Col>
          <Col xs={24}>
            <FormItem
              label="Contraseña"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Escribe una nueva contraseña',
                },
              ]}
            >
              <Input.Password
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: setPasswordVisible,
                }}
                addonAfter={
                  <Button type="text" size="small" onClick={handleRandomPassword}>Generar contraseña</Button>
                }
              />
            </FormItem>
          </Col>
          <Col xs={24}>
            <FormItem
              dependencies={['password']}
              label="Confirmar contraseña"
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: 'Confirma tu nueva contraseña',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Las contraseñas no coinciden.'));
                  },
                }),
                {
                  min: 8,
                  required: true,
                  message:
                    'La contraseña debe de ser de mínimo 8 caracteres.',
                },
                {
                  required: true,
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                  message:
                    'La contraseña debe contener por lo menos un número y una letra mayúscula.',
                },
              ]}
            >
              <Input.Password
                visibilityToggle={{
                  visible: passwordConfirmVisible,
                  onVisibleChange: setPasswordConfirmVisible,
                }}
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <Col>
            <Button
              type="primary"
              className="bg-blue-800"
              loading={isLoading}
              htmlType="submit"
              block={mobileDevice}
              style={{ marginTop: '1.3rem' }}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default CreateEmployees;
