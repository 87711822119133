import styled from 'styled-components';

const TitleExpandedRowStyled = styled.div`
  font-size:2.5em;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #000;
  padding: 10px 0;
  background-color: #89a7e8;
  text-align: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;

  @media (max-width: 768px) {
    font-size: 1.5em;
  }

  @media (max-width: 567px) {
    font-size: 1.3em;
  }
`;

TitleExpandedRowStyled.defaultProps = {
};

export const SubtitleExpandedRowStyled = styled(TitleExpandedRowStyled)`
  font-weight: 600;
  font-size: 1em;
  letter-spacing: 0.3px;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
`;

export default TitleExpandedRowStyled;
