import FormItem from 'Shared/Components/Form/Item';
import SelectUnities from 'Shared/Selects/Unities';
import {
  DatePicker,
  Select,
  Popover,
  Button,
  Form,
  Row,
  Col,
  Space,
} from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import React from 'react';

const { Option } = Select;

function PickerWithType({ type, onChange }) {
  if (type === 'historico') return null;
  if (type === 'date') return <DatePicker onChange={onChange} />;
  return (
    <DatePicker
      picker={type}
      onChange={onChange}
      placeholder=""
      style={{
        float: 'right',
      }}
    />
  );
}

function ContentPopover(props) {
  const { onSubmit } = props;
  const [form] = Form.useForm();
  const lapse = Form.useWatch('lapse', form);

  const resetForm = () => {
    form.resetFields();
    onSubmit({
      lapse: 'historico',
      sort: 'recent',
      categories: 'all',
    });
  };

  const onFinish = (values) => {
    onSubmit(values);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={{
        lapse: 'historico',
        sort: 'recent',
        categories: 'all',
      }}
    >
      <Row type="flex" gutter={20}>
        <Col span={lapse === 'historico' ? 24 : 12}>
          <FormItem
            label="Selecciona un periodo"
            name="lapse"
          >
            <Select style={{ width: '100%' }}>
              <Option value="historico">Historico</Option>
              <Option value="month">Mensual</Option>
              <Option value="quarter">Trimestral</Option>
              <Option value="year">Anual</Option>
            </Select>
          </FormItem>
        </Col>
        <Col xs={12} lg={12}>
          <FormItem
            label=""
            name="dateLapse"
            style={{
              marginTop: '21px',
              display: lapse === 'historico' ? 'none' : 'block',
            }}
          >
            <PickerWithType
              type={lapse}
            />
          </FormItem>
        </Col>

        <Col xs={24}>
          <FormItem
            label="Categorías"
            name="categories"
          >
            <SelectUnities
              beingFiltered
              mode="multiple"
            />
          </FormItem>
        </Col>
        <Col xs={24}>
          <FormItem
            label="Ordenar"
            name="sort"
          >
            <Select style={{ width: '100%' }}>
              <Option value="recent">Más recientes</Option>
              <Option value="old">Más antiguos</Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="end">
        <Col>
          <Space>
            <Button
              type="primary"
              className="bg-gray-300 text-gray-800"
              onClick={resetForm}
            >
              Reestablecer
            </Button>
            <Button
              type="primary"
              className="bg-blue-800"
              htmlType="submit"
            >
              Aplicar
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}

function FilterExpenses(props) {
  const { onSubmit } = props;

  return (
    <Popover
      content={<ContentPopover onSubmit={onSubmit} />}
      placement="bottom"
      trigger="click"
      overlayInnerStyle={{
        maxWidth: '400px',
        width: '350px',
      }}
    >
      <Button
        type="text"
        icon={<FilterOutlined style={{ fontWeight: 'bold' }} />}
        style={{
          float: 'right',
          fontWeight: 'bold',
        }}
      >
        Filtros avanzados
      </Button>
    </Popover>
  );
}

export default FilterExpenses;
