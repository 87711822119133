import Logo from 'Shared/Images/logo.png';
import { dateFormat, moneyFormat } from 'Shared/Utils';
import { useRequestProcessor } from 'Shared/customHooks';
import { RequestedServicesAPI } from 'Shared/Services/services';
import { useParams } from 'react-router-dom';
import React from 'react';
import {
  Page,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
  Text,
} from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
  },
  section: {
    width: '100%',
    margin: 20,
    padding: 20,
    flexGrow: 1,
  },
  logo: {
    width: '200px',
    height: '90px',
    marginLeft: '20px',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 8,
    paddingBottom: 8,
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 8,
    paddingBottom: 8,
  },
  half: {
    width: '50%',
  },
  full: {
    width: '100%',
  },
  textCenter: {
    textAlign: 'center',
  },
  title: {
    fontSize: '12px',
    fontFamily: 'Helvetica',
  },
  bigTitle: {
    fontSize: '11px',
    fontFamily: 'Helvetica-Bold',
  },
  bold: {
    fontWeight: 'bolder',
    fontFamily: 'Helvetica-Bold',
  },
  table: {
    header: {
      width: '100%',
      marginTop: 20,
      paddingTop: 3,
      paddingBottom: 3,
      backgroundColor: '#003ea0',
      color: '#FFFFFF',
      fontSize: '8px',
    },
    body: {
      paddingTop: 3,
      paddingBottom: 3,
      paddingLeft: 5,
      paddingRight: 5,
      color: '#000000',
      fontSize: '9px',
      borderBottom: '1px solid #CCCCCC',
      borderLeft: '1px solid #CCCCCC',
      borderRight: '1px solid #CCCCCC',
    },
  },
  tableSide: {
    header: {
      paddingTop: 3,
      paddingBottom: 3,
      backgroundColor: '#003ea0',
      color: '#FFFFFF',
      fontSize: '8px',
      borderBottom: '1px solid #CCCCCC',
    },
    body: {
      paddingTop: 3,
      paddingBottom: 3,
      paddingLeft: 5,
      paddingRight: 5,
      color: '#000000',
      fontSize: '8px',
      borderBottom: '1px solid #CCCCCC',
      borderLeft: '1px solid #CCCCCC',
      borderRight: '1px solid #CCCCCC',
    },
  },
});

// Create Document Component
function MyDocument(props) {
  const { data } = props;
  return (
    <Document title="Solicitud de servicio">
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.flexRow}>
            <View style={styles.half}>
              <Image src={Logo} style={styles.logo} />
            </View>
            <View style={[styles.half, styles.title]}>
              <Text style={styles.textCenter}>Solicitud de servicio</Text>
              <Text
                style={[
                  styles.textCenter,
                  {
                    marginTop: 50,
                    fontFamily: 'Helvetica-Bold',
                    fontSize: 8,
                  },
                ]}
              >
                Número de requisición
              </Text>
              <Text
                style={[
                  styles.textCenter,
                  {
                    marginTop: 1,
                    fontFamily: 'Helvetica-Bold',
                    color: '#FF0800',
                    fontSize: 14,
                    letterSpacing: 1,
                  },
                ]}
              >
                {data.numRequisition}
              </Text>
            </View>
          </View>

          <View style={styles.flexColumn}>
            <View style={[styles.table.header, styles.full, styles.textCenter]}>
              <Text>
                DATOS DEL SOLICITANTE
              </Text>
            </View>
            <View style={[styles.table.body, styles.full]}>
              <Text>
                <Text style={styles.bold}>Cliente:</Text>
                {' '}
                {data.client.fullName}
              </Text>
            </View>
            <View style={[styles.table.body, styles.full]}>
              <Text>
                <Text style={styles.bold}>Ejecutivo:</Text>
                {' '}
                {data.user.fullName}
              </Text>
            </View>
          </View>

          <View style={[styles.flexColumn, { paddingBottom: 0 }]}>
            <Text style={[styles.textCenter, styles.bigTitle]}>CARACTERISTICAS DE LA CARGA</Text>
          </View>

          <View style={styles.flexColumn}>
            <View style={[styles.table.header, styles.full, styles.textCenter, { marginTop: 8 }]}>
              <Text>
                LUGAR DE ORIGEN / LUGAR DE DESTINO
              </Text>
            </View>
            <View style={[styles.table.body, styles.full, styles.textCenter]}>
              <Text>
                {`${data.placeOrigin} / ${data.placeDestination}`}
              </Text>
            </View>
          </View>

          <View style={[styles.flexRow, {
            gap: '10px', paddingTop: 0, flexGrow: 1, flex: 1,
          }]}
          >
            <View style={[styles.half, { flexBasis: '250px', flexGrow: 1 }]}>
              <View style={[styles.flexRow, { paddingBottom: 0 }]}>
                <View style={[styles.tableSide.header, styles.half, styles.textCenter]}>
                  <Text>
                    MODALIDAD
                  </Text>
                </View>
                <View style={[styles.tableSide.body, styles.half, styles.textCenter, { borderTop: '1px solid #CCCCCC' }]}>
                  <Text>
                    {data.mode}
                  </Text>
                </View>
              </View>
              <View style={[styles.flexRow, { padding: 0 }]}>
                <View style={[styles.tableSide.header, styles.half, styles.textCenter]}>
                  <Text>
                    TIPO DE CARGA
                  </Text>
                </View>
                <View style={[styles.tableSide.body, styles.half, styles.textCenter]}>
                  <Text>
                    {data.typeFreight}
                  </Text>
                </View>
              </View>
              <View style={[styles.flexRow, { padding: 0 }]}>
                <View style={[styles.tableSide.header, styles.half, styles.textCenter]}>
                  <Text>
                    TIPO DE TRASLADO
                  </Text>
                </View>
                <View style={[styles.tableSide.body, styles.half, styles.textCenter]}>
                  <Text>
                    {data.typeMove}
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.half, { flexBasis: '250px', flexGrow: 1 }]}>
              <View style={[styles.flexRow, { paddingBottom: 0 }]}>
                <View style={[styles.tableSide.header, styles.half, styles.textCenter]}>
                  <Text>
                    FECHA DE CARGA
                  </Text>
                </View>
                <View style={[styles.tableSide.body, styles.half, styles.textCenter, { borderTop: '1px solid #CCCCCC' }]}>
                  <Text>
                    {dateFormat(data.dateFreight)}
                  </Text>
                </View>
              </View>
              <View style={[styles.flexRow, { paddingTop: 14 }]}>
                <View style={[styles.tableSide.header, styles.half, styles.textCenter]}>
                  <Text>
                    FECHA DE ENTREGA
                  </Text>
                </View>
                <View style={[styles.tableSide.body, styles.half, styles.textCenter, { borderTop: '1px solid #CCCCCC' }]}>
                  <Text>
                    {dateFormat(data.dateDelivery)}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View style={[styles.flexColumn, {
            position: 'absolute', width: '100%', top: '340px', left: '20px',
          }]}
          >
            <View style={[styles.table.header, styles.full, styles.textCenter]}>
              <Text>
                OBSERVACIONES O SERVICIOS ADICIONALES
              </Text>
            </View>
            <View style={[styles.table.body, styles.full, { height: '100px' }]}>
              <Text>
                {data.observation ? data.observation : 'No hay observaciones o servicios adicionales.'}
              </Text>
            </View>
          </View>

          <View style={[styles.flexColumn, {
            width: '100%', top: '485px', left: '20px', position: 'absolute',
          }]}
          >
            <Text style={[styles.textCenter, styles.bigTitle]}>REFERENCIAS</Text>
          </View>

          <View style={[styles.flexColumn, {
            width: '100%', top: '490px', left: '20px', position: 'absolute',
          }]}
          >
            <View style={[styles.table.header, styles.full, styles.textCenter]}>
              <Text>
                CONTENEDOR
              </Text>
            </View>
            <View style={[styles.table.body, styles.full, styles.textCenter]}>
              <Text>
                {data.billLandings ? data.billLandings : ' '}
              </Text>
            </View>
            <View style={[styles.table.header, styles.full, styles.textCenter]}>
              <Text>
                UNIDAD / OPERADOR
              </Text>
            </View>
            <View style={[styles.table.body, styles.full, styles.textCenter]}>
              <Text>
                {`${data.assignTruck.truck.name}(${data.assignTruck.truck.model}) / ${data.assignTruck.driver.fullName}`}
              </Text>
            </View>
            <View style={[styles.table.header, styles.full, styles.textCenter]}>
              <Text>
                TARIFA DE VENTA
              </Text>
            </View>
            <View style={[styles.table.body, styles.full, styles.textCenter]}>
              <Text>
                {moneyFormat(data.rateSale)}
              </Text>
            </View>
          </View>

        </View>
      </Page>
    </Document>
  );
}

function PDFDocument() {
  const { requestedId } = useParams();
  const { query } = useRequestProcessor();

  const { data, isLoading } = query(
    ['service', requestedId],
    () => RequestedServicesAPI.getRequestedService(requestedId),
  );

  if (isLoading) return 'Generando PDF...';

  return (
    <PDFViewer style={{
      height: '100vh',
      width: '100%',
    }}
    >
      <MyDocument data={data} />
    </PDFViewer>
  );
}

export default PDFDocument;
