import FormItem from 'Shared/Components/Form/Item';
import DisplayMap from 'Shared/MapView';
import React, { useState, useEffect } from 'react';
import {
  Input,
  Row,
  Col,
  Form,
  Button,
} from 'antd';

function Location() {
  const [form] = Form.useForm();
  const [userLocation, setUserLocation] = useState(null);
  const [noService, setNoService] = useState(null);
  const [simulation, setSimulation] = useState(false);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition((position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation({ latitude, longitude });
      }, (error) => {
        // eslint-disable-next-line no-console
        console.error('Error getting user location:', error);
      });
    } else {
      // eslint-disable-next-line no-console
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const onFinish = async (values) => {
    setNoService(() => values.no_service);

    setSimulation(false);
  };

  const handleClick = () => {
    setSimulation(!simulation);
  };

  useEffect(() => {
    getUserLocation();
  }, []);

  return (
    <>
      <Form
        className="login-form"
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Row justify="center" gutter={16}>
          <Col xs={24} md={8}>
            <FormItem
              label="No. Servicio"
              name="no_service"
              rules={[
                {
                  required: true,
                  message: 'Ingresa el no. de servicio.',
                },
              ]}
            >
              <Input />
            </FormItem>
          </Col>
          <Col xs={24} md={4}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: '1.5rem', marginRight: '0.5rem' }}
            >
              Buscar
            </Button>

            <Button
              type="primary"
              onClick={handleClick}
              style={{ marginTop: '1.5rem', marginRight: '0.5rem' }}
            >
              Simulación
            </Button>
          </Col>
        </Row>
      </Form>
      {noService && userLocation && !simulation && (
        <Row justify="center" style={{ marginTop: '2rem' }}>
          <Col xs={24}>
            <iframe src="https://atlantida2.mx/index.php?su=5AB08B5B7C0096338D4B30828AC2A183&m=true" title="Map" />
          </Col>
        </Row>
      )}

      {simulation && (
        <Row justify="center" style={{ marginTop: '2rem' }}>
          <Col xs={24}>
            <DisplayMap
              lat={38.85682}
              long={-99.0802}
              zoom={7}
            />
          </Col>
        </Row>
      )}
    </>
  );
}

export default Location;
