import styled from 'styled-components';
import { Table } from 'antd';

const TableStyled = styled(Table)`
  color: gray!important;
  cursor: default;

  .ant-table-thead {
    tr > td.ant-table-cell,
    th {
      background: #003EA0 !important;
      color: #FFF !important;

      .ant-table-column-sorters {
        span.ant-table-column-sorter.ant-table-column-sorter-full {
          color: rgba(255, 255, 255, 0.9);
        }
      }
    }
  }
  .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
    top: 25%;
    left: 0%;
    padding: 20px;
  }

  .ant-table-tbody > tr.warning,
  .ant-table-tbody > tr.warning > .ant-table-cell-row-hover {
    background-color: #cc3300;
    color: #fff;
  }

  .ant-table-tbody > tr.caution,
  .ant-table-tbody > tr.caution > .ant-table-cell-row-hover {
    background-color: #c69035;
    color: #fff;
  }

  .ant-table-tbody > tr.caution:hover > td,
  .ant-table-tbody > tr.warning:hover > td {
    color: #fff;
    font-weight: bold;
    background-color: inherit;
  }

  .ant-table-tbody > tr.caution > td.ant-table-cell-fix-right,
  .ant-table-tbody > tr.warning > td.ant-table-cell-fix-right {
    color: #fff;
  }

  .ant-table-tbody > tr.caution:hover > td.ant-table-cell-fix-left-last,
  .ant-table-tbody > tr.warning:hover > td.ant-table-cell-fix-left-last {
    color: #fff;
    background: inherit;
  }

  .ant-table-cell-fix-left {
    background: #003EA0 !important;
    color: #FFF;
    font-weight: bold;
  }

  .ant-table-cell-fix-right {
    background: transparent;
    color: #000;
    font-weight: bold;
  }

  .ant-table-cell-fix-left.ant-table-row-expand-icon-cell {
    color: #000;

    button {
      border-radius: 0;
    }
  }
`;

TableStyled.defaultProps = {
};

export default TableStyled;
