import { useAuth } from 'Shared/Providers/Auth';
import CustomerLogo from 'Shared/Icons/rating.png';
import DriverLogo from 'Shared/Icons/driver.png';
import VendorLogo from 'Shared/Icons/enterprise.png';
import EmployeeLogo from 'Shared/Icons/employees.png';
import OrdersLogo from 'Shared/Icons/choices.png';
import TruckLogo from 'Shared/Icons/truck.png';
import PlatformLogo from 'Shared/Icons/flat-bed-truck.png';
import CalendarLogo from 'Shared/Icons/calendar.png';
import ExpensesLogo from 'Shared/Icons/poor.png';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  UserOutlined,
  IdcardOutlined,
  SafetyCertificateOutlined,
  BookOutlined,
  CarOutlined,
  BankOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { Menu } from 'antd';

const MenuStyled = styled(Menu)`
  background: transparent!important;
  & .ant-menu-item.ant-menu-item-selected {
    background-color: #ABC4EA;
    color: #1D1D1B;
    border-radius: 0;
    width: 100%;

    margin-inline: 0;
    margin: 0;
  }

  ${(props) => (
    props.mobile && `
      color: #FFF;

      .ant-menu-item {
        height: auto;
        border-radius: 0;
      }

      .ant-menu-submenu-arrow {
        display: none;
      }

      .ant-menu-item-only-child {
        margin-bock: 0;
        padding: 5px 0;

        & > .ant-menu-title-content {
          font-size: 1.2rem;

          span.anticon {
            font-size: 1rem!important;
            margin-right: 10px!important;
          }
        }
      }

      & > .ant-menu-submenu {
        & > .ant-menu-submenu-title {
          margin-block: 0;
          padding: 15px 0;
          height: auto;

          > span.anticon.ant-menu-item-icon {
            font-size: 1.3rem;
          }
          > span.ant-menu-title-content {
            font-size: 2rem;
          }
        }
      }

      & > .ant-menu-item {
        padding: 15px 0;

        > span.anticon.ant-menu-item-icon {
          font-size: 1.3rem;
        }
        > span.ant-menu-title-content {
          font-size: 2rem;
        }
      }
  `)}
`;

const MenuBottomStyled = styled(MenuStyled)`
  position: ${(props) => (props.mobile ? 'relative' : 'absolute')};
  display: block;
  width: 100%!important;

  ${(props) => (
    props.mobile ? `
      margin-block: 0;
    ` : `
      bottom: 50px;
    `
  )}
`;

const IconsStyled = styled.img`
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 4px;
  vertical-align: baseline;
`;

const MenuMOC = [
  {
    key: '1',
    icon: <IdcardOutlined />,
    label: 'Contactos', // Aqui vendran y se podra organizar el listado de los clientes, conductores, proveedores y trabajadores.
    children: [
      {
        label: (
          <Link to="/clients">
            <IconsStyled src={CustomerLogo} />
            Clientes
          </Link>
        ),
        key: 'clients',
      },
      {
        label: (
          <Link to="/drivers">
            <IconsStyled src={DriverLogo} />
            Conductores
          </Link>
        ),
        key: 'drivers',
      },
      {
        label: (
          <Link to="/vendors">
            <IconsStyled src={VendorLogo} />
            Proveedores
          </Link>
        ),
        key: 'vendors',
      },
      {
        label: (
          <Link to="/employees">
            <IconsStyled src={EmployeeLogo} />
            Recursos
          </Link>
        ),
        key: 'employees',
      },
    ],
  },
  {
    key: '2',
    icon: <BookOutlined />,
    label: 'Servicios',
    children: [
      {
        label: (
          <Link to="/request-service">
            <IconsStyled src={OrdersLogo} />
            Solicitudes
          </Link>
        ),
        key: 'request-services',
      },
    ],
  },
  {
    key: '3',
    icon: <SafetyCertificateOutlined />,
    label: <Link to="/insurances">Pólizas</Link>,
  },
  {
    key: '4',
    icon: <CarOutlined />,
    label: 'Transporte',
    children: [
      {
        label: (
          <Link to="/trucks">
            <IconsStyled src={TruckLogo} />
            Unidades
          </Link>
        ),
        key: 'trucks',
      },
      {
        label: (
          <Link to="/calendar">
            <IconsStyled src={CalendarLogo} />
            Programación
          </Link>
        ),
        key: 'calendar-trucks',
      },
      {
        label: (
          <Link to="/platforms">
            <IconsStyled src={PlatformLogo} />
            Plataformas
          </Link>
        ),
        key: 'platforms',
      },
    ],
  },
  {
    key: '5',
    icon: <BankOutlined />,
    label: 'Finanzas',
    children: [
      {
        label: (
          <Link to="/expenses">
            <IconsStyled src={ExpensesLogo} />
            Gastos
          </Link>
        ),
        key: 'expenses',
      },
    ],
  },
];

const MenuClient = [
  {
    key: '1',
    label: 'Carga',
  },
  {
    key: '2',
    label: 'Evidencias',
  },
];

function ChooseItemsMenu(menuClient) {
  const location = useLocation();

  if (location.pathname === '/services' || menuClient) return [];

  if (menuClient) return MenuClient;
  return MenuMOC;
}

export function MenuOwn({
  mobile = false,
  collapsed,
  setCollapsed,
  menuClient = false,
}) {
  return (
    <MenuStyled
      mobile={mobile}
      onClick={() => { if (mobile) setCollapsed(!collapsed); }}
      theme="dark"
      mode="inline"
      items={ChooseItemsMenu(menuClient)}
    />
  );
}

export function MenuBottomOwn({
  mobile = false,
  menuClient = false,
}) {
  const auth = useAuth();

  if (menuClient) return null;

  return (
    <MenuBottomStyled
      mobile={mobile}
      theme="dark"
      mode="inline"
      items={[
        {
          key: 'user',
          icon: <UserOutlined />,
          label: auth.user?.fullName,
          children: [
            {
              label: 'Cerrar sesión',
              key: 'logout',
              onClick() {
                auth.logout();
              },
            },
          ],
        },
      ]}
    />
  );
}
