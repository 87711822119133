import LogoMOC from 'Shared/Images/logo_white.png';
import React from 'react';
import styled from 'styled-components';
import { Layout, Button } from 'antd';
import { Outlet } from 'react-router-dom';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';

const { Sider, Content } = Layout;

const SiderStyled = styled(Sider)`
  display: flex;
  overflow: auto;
  min-height: 100vh;
  background: #003EA0!important;

  > .ant-layout-sider-children {
    width: 100%;
  }
`;

const ContentStyled = styled(Content)`
  margin: 24px 16px;
  padding: 24px;
  min-height: 280px;
  background: white;
  overflow-y: auto;
  max-height: 100vh;
`;

const Logo = styled.img`
  display: block;
  height: ${(props) => (props.collapsed ? '30px' : '60px')};
  margin: 34px auto;
  border-radius: 6px;
`;

const ButtonCollapsedStyled = styled(Button)`
  font-size: 20px;
  height: 64px;
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;

  &:hover {
    color: white!important;
  }
`;

function LayoutDesktop({
  Menu,
  MenuBottom,
  collapsed,
  setCollapsed,
}) {
  return (
    <Layout>
      <SiderStyled
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
      >
        <Logo src={LogoMOC} collapsed={collapsed} />
        {Menu}
        {MenuBottom}

        <ButtonCollapsedStyled
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
        />
      </SiderStyled>
      <Layout>
        <ContentStyled>
          <Outlet />
        </ContentStyled>
      </Layout>
    </Layout>
  );
}

export default LayoutDesktop;
