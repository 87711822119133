import { useRequestProcessor } from 'Shared/customHooks';
import FormItem from 'Shared/Components/Form/Item';
import { DriversAPI } from 'Shared/Services/drivers';
import { TransactionsAPI } from 'Shared/Services/transactions';
import DatePickerStyled from 'Shared/Components/DatePicker';
import SelectUnities from 'Shared/Selects/Unities';
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  notification,
  InputNumber,
} from 'antd';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

function CreateExpense(props) {
  const {
    mobileDevice,
    onSuccess,
    typeAction,
    data,
  } = props;
  const { mutation } = useRequestProcessor();
  const [apiNotification, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const {
    isLoading: isLoadingCreate,
    mutate: mutateCreate,
  } = mutation('transaction', (values) => TransactionsAPI.createTransaction(values));

  const {
    isLoading: isLoadingUpdate,
    mutate: mutateUpdate,
  } = mutation('updateDriver', ({ id, values }) => DriversAPI.updateDriver(id, values));

  const onFinish = async (values) => {
    if (typeAction === 'create') {
      const formatTransactionDate = dayjs(values.transactionDate).format('YYYY-MM-DD');
      const formatValues = {
        ...values,
        transactionDate: formatTransactionDate,
        isExpense: 1,
      };

      mutateCreate(formatValues, {
        onSuccess: (response) => {
          const { message } = response;
          apiNotification.success({
            description: message,
          });
          form.resetFields();
          onSuccess();
        },
        onError: (error) => {
          const { data: info } = error.response;
          const { message } = info;
          apiNotification.error({
            description: message,
          });
        },
      });
    } else if (typeAction === 'update') {
      const { id } = data;
      mutateUpdate({
        id,
        values,
      }, {
        onSuccess: (response) => {
          const { message } = response;
          apiNotification.success({
            description: message,
          });
          form.resetFields();
          onSuccess();
        },
        onError: (error) => {
          const { data: info } = error.response;
          const { message } = info;
          apiNotification.error({
            description: message,
          });
        },
      });
    }
  };

  useEffect(() => {
    if (typeAction === 'create') form.resetFields();
    else form.setFieldsValue(data);
  }, [form, data, typeAction]);

  return (
    <>
      {contextHolder}
      <Form
        className="login-form"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={data}
      >
        <Row type="flex" gutter={20}>
          <Col xs={24}>
            <FormItem
              label="Concepto"
              name="description"
              rules={[
                { required: true, message: 'Ingresa el concepto.' },
              ]}
            >
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem
              label="Cantidad"
              name="amount"
              rules={[
                { required: true, message: 'Ingresa la cantidad del gasto.' },
              ]}
            >
              <InputNumber
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                className="w-full"
              />
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem
              label="Fecha"
              name="transactionDate"
              rules={[
                { required: true, message: 'Ingresa la fecha del gasto.' },
              ]}
            >
              <DatePickerStyled format="DD/MM/YYYY" />
            </FormItem>
          </Col>
          <Col xs={24}>
            <FormItem
              label="Categoría"
              name="categoryId"
              rules={[
                { required: true, message: 'Selecciona una categoría.' },
              ]}
            >
              <SelectUnities />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <Col>
            <Button
              type="primary"
              className="bg-blue-800"
              loading={isLoadingCreate || isLoadingUpdate}
              htmlType="submit"
              block={mobileDevice}
              style={{ marginTop: '1.3rem' }}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default CreateExpense;
