import React from 'react';
import styled from 'styled-components';
import { Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

const IconStyled = styled(MoreOutlined)`
  cursor: pointer;

  &:hover {
    color: #0d47a1;
  }
`;

function Actions(props) {
  const { menu } = props;
  return (
    <Dropdown overlayClassName="actions" menu={menu} {...props}>
      <IconStyled />
    </Dropdown>
  );
}

export default Actions;
