/* eslint-disable */
import PageHeader from 'Shared/Components/PageHeader';
import { useRequestProcessor } from 'Shared/customHooks';
import DescriptionsStyled from 'Shared/Components/Descriptions';
import { SubtitleExpandedRowStyled } from 'Shared/Components/TitleExpandedRow';
import FormItem from 'Shared/Components/Form/Item';
import { RequestedServicesAPI } from 'Shared/Services/services';
import ShowRequestedService from 'Services/Schedule/utils';
import SelectVendors from 'Shared/Selects/Vendors';
import { InboxOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Form,
  InputNumber,
  Button,
  Input,
  notification,
  Upload,
} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

const { Dragger } = Upload;

function ScheduleService() {
  const [form] = Form.useForm();
  const vendor = Form.useWatch('vendorId', form);
  const ratePurchase = Form.useWatch('ratePurchase', form);
  const rateSale = Form.useWatch('rateSale', form);
  const navigate = useNavigate();
  const [apiNotification, contextHolder] = notification.useNotification();
  const [isExternVendor, setIsExternVendor] = useState(false);
  const { mutation } = useRequestProcessor();
  const { state } = useLocation();
  const { numRequisition, title, status } = state;

  const {
    isLoading: isLoadingUpdate,
    mutate: mutateUpdate,
  } = mutation('updateRequestedServices', ({ id, values }) => RequestedServicesAPI.updateRequestedServices(id, values));

  useEffect(() => {
    if (vendor) {
      if (vendor !== 'own') setIsExternVendor(true);
      else setIsExternVendor(false);
    }
  }, [vendor]);

  useEffect(() => {
    if (isExternVendor && ratePurchase) {
      const profit = rateSale - ratePurchase;
      form.setFieldValue('profit', profit);
    }
  }, [isExternVendor, ratePurchase]);

  useEffect(() => {
    let setStatus = '';

    if (status === 'Abierto') setStatus = 'Programar';
    if (status === 'Programar') setStatus = 'En servicio';
    if (status === 'En servicio') setStatus = 'Finalizado';

    form.setFieldsValue({
      status: setStatus,
    });

  }, [status]);

  const onFinish = (values) => {
    const { id } = state;
    mutateUpdate({
      id,
      values,
    }, {
      onSuccess: (response) => {
        const { message } = response;
        apiNotification.success({
          description: message,
        });

        setTimeout(() => {
          navigate('/request-service');
        }, 2000);
      },
      onError: (error) => {
        const { info } = error.response;
        const { message } = info;
        apiNotification.error({
          description: message,
        });
      },
    });
  };

  return (
    <>
      {contextHolder}
      <PageHeader title={title} />

      <ShowRequestedService
        title={title}
        numRequisition={numRequisition}
        data={state}
      />

      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          rateSale: state.rateSale,
          profit: state.rateSale,
        }}
      >
        {(status !== 'Abierto') && (
          <Row type="flex" gutter={0}>
            <Col xs={24}>
              <DescriptionsStyled
                title={
                  (
                    <SubtitleExpandedRowStyled>
                      Completa la siguiente información.
                    </SubtitleExpandedRowStyled>
                  )
                }
                bordered
                size="small"
              />
            </Col>
          </Row>
        )}
        <Row type="flex" gutter={20}>
          {status === 'En servicio' && (
            <>            
              <Col xs={24} lg={8}>
                <DescriptionsStyled
                  title={<SubtitleExpandedRowStyled>Proveedor</SubtitleExpandedRowStyled>}
                  bordered
                  size="small"
                />
                <FormItem
                  label="Selecciona un proveedor"
                  name="vendorId"
                  rules={[{ required: true, message: 'Selecciona un proveedor.' }]}
                >
                  <SelectVendors placeholder="Selecciona un proveedor" />
                </FormItem>
              </Col>
              <Col xs={24} lg={8}>
                <DescriptionsStyled
                  title={<SubtitleExpandedRowStyled>Tarifas</SubtitleExpandedRowStyled>}
                  bordered
                  size="small"
                />
                <Row type="flex" gutter={4}>
                  <Col xs={24} lg={isExternVendor ? 12 : 24}>
                    <FormItem
                      label="Venta"
                      name="rateSale"
                      rules={[{ required: true, message: 'Ingresa una tarifa de venta.' }]}
                    >
                      <InputNumber
                        disabled
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        className="w-full"
                      />
                    </FormItem>
                  </Col>

                  {isExternVendor && (
                    <Col xs={24} lg={12}>
                      <FormItem
                        label="Compra"
                        name="ratePurchase"
                        rules={[{ required: true, message: 'Ingresa una tarifa de compra.' }]}
                      >
                        <InputNumber
                          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          className="w-full"
                        />
                      </FormItem>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col xs={24} lg={8}>
                <DescriptionsStyled
                  title={<SubtitleExpandedRowStyled>Profit</SubtitleExpandedRowStyled>}
                  bordered
                  size="small"
                />
                <Row type="flex" gutter={4}>
                  <Col xs={24} lg={24}>
                    <FormItem
                      label="Ganancia real"
                      name="profit"
                      rules={[{ required: true, message: 'Ingresa la ganancia real.' }]}
                    >
                      <InputNumber
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        className="w-full"
                      />
                    </FormItem>
                  </Col>
                </Row>
              </Col>
              <Col xs={24}>
                <DescriptionsStyled
                  title={<SubtitleExpandedRowStyled>Evidencias</SubtitleExpandedRowStyled>}
                  bordered
                  size="small"
                />
                <FormItem
                  label=""
                  name="files"
                  rules={[{ required: false, message: 'Ingresa por lo menos una evidencia.' }]}
                >
                  <Dragger
                    beforeUpload={() => false}
                    accept="image/png, image/jpeg, image/jpg, .pdf"
                    className="rounded-none"
                    multiple
                    name="file"
                  >
                    <p>
                      <InboxOutlined className="text-7xl mb-4" />
                    </p>
                    <p>
                      Da clic o arrastra los archivos a esta zona.
                    </p>
                  </Dragger>
                </FormItem>
              </Col>
            </>
          )}
          {(status === 'Programar' || status === 'Reprogramado') && (
            <Col xs={24}>
              <DescriptionsStyled
                title={<SubtitleExpandedRowStyled>GPS</SubtitleExpandedRowStyled>}
                bordered
                size="small"
              />
              <Row type="flex" gutter={4}>
                <Col xs={24} lg={24}>
                  <FormItem
                    label="URL de mirror"
                    name="mirrorUrl"
                    rules={[
                      { type: 'url', message: 'Ingresa una URL válida.' },
                      { required: true, message: 'Ingresa la URL de mirror.' },
                    ]}
                  >
                    <Input />
                  </FormItem>
                </Col>
              </Row>
            </Col> 
          )}
          
        </Row>

        <FormItem
          hidden
          label="Status"
          name="status"
        >
          <Input />
        </FormItem>

        <Row type="flex" justify="end">
          <Col>
            <Button
              type="primary"
              className="bg-blue-800"
              htmlType="submit"
              style={{ marginTop: '1.3rem' }}
              loading={isLoadingUpdate}
            >
              {status === 'Abierto' && 'Programar'}
              {(status === 'Programar' || status === 'Reprogramado') && 'Entrar en servicio'}
              {status === 'En servicio' && 'Finalizar'}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default ScheduleService;
