import FormItem from 'Shared/Components/Form/Item';
import SelectTrucks from 'Shared/Selects/Trucks';
import SelectPlatformTrucks from 'Shared/Selects/PlatformTrucks';
import {
  Select,
  Popover,
  Button,
  Form,
  Row,
  Col,
  Space,
  Checkbox,
} from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';

const { Option } = Select;

function ContentPopover(props) {
  const { onSubmit } = props;
  const [form] = Form.useForm();
  const typeTransport = Form.useWatch('typeTransport', form);

  const resetForm = () => {
    form.resetFields();
    onSubmit({
      typeTransport: 'all',
    });
  };

  const onFinish = (values) => {
    onSubmit(values);
  };

  useEffect(() => {
    if (typeTransport) {
      form.resetFields(['truck_id', 'platform_id']);
    }
  }, [typeTransport]);

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      initialValues={{
        typeTransport: 'all',
      }}
    >
      <Row type="flex" gutter={20}>
        <Col xs={24}>
          <FormItem
            label="Selecciona el tipo de póliza"
            name="typeTransport"
          >
            <Select style={{ width: '100%' }}>
              <Option value="all">Todas</Option>
              <Option value="truck">Unidad</Option>
              <Option value="platform">Plataforma</Option>
            </Select>
          </FormItem>
        </Col>

        {typeTransport === 'truck' && (
          <Col xs={24}>
            <FormItem
              label="Selecciona la unidad"
              name="truckId"
              rules={[
                { required: true, message: 'Selecciona la unidad.' },
              ]}
            >
              <SelectTrucks />
            </FormItem>
          </Col>
        )}

        {typeTransport === 'platform' && (
          <Col xs={24}>
            <FormItem
              label="Selecciona la plataforma"
              name="platformId"
              rules={[
                { required: true, message: 'Selecciona la plataforma.' },
              ]}
            >
              <SelectPlatformTrucks />
            </FormItem>
          </Col>
        )}

        <Col xs={24}>
          <FormItem name="renewed" valuePropName="checked">
            <Checkbox>Mostrar solo pólizas renovadas</Checkbox>
          </FormItem>
        </Col>
      </Row>
      <Row type="flex" justify="end">
        <Col>
          <Space>
            <Button
              type="primary"
              className="bg-gray-300 text-gray-800"
              onClick={resetForm}
            >
              Reestablecer
            </Button>
            <Button
              type="primary"
              className="bg-blue-800"
              htmlType="submit"
            >
              Aplicar
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}

function FilterInsurances(props) {
  const { onSubmit } = props;

  return (
    <Popover
      content={<ContentPopover onSubmit={onSubmit} />}
      placement="bottom"
      trigger="click"
      overlayInnerStyle={{
        maxWidth: '400px',
        width: '350px',
      }}
    >
      <Button
        type="text"
        icon={<FilterOutlined style={{ fontWeight: 'bold' }} />}
        style={{
          float: 'right',
          fontWeight: 'bold',
        }}
      >
        Filtros avanzados
      </Button>
    </Popover>
  );
}

export default FilterInsurances;
