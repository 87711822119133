import PageHeader from 'Shared/Components/PageHeader';
import { useWindowWidthSize, useRequestProcessor } from 'Shared/customHooks';
import Loader from 'Shared/Components/Loader';
import DrawerStyled from 'Shared/Components/Drawer';
import TitleModal from 'Shared/Components/TitleModal';
import TableStyled from 'Shared/Components/Table';
import ActionsRequestService from 'Services/Request/Actions';
import { RequestedServicesAPI } from 'Shared/Services/services';
import { CarOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Button,
  Input,
  notification,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { columns, expandedRowRender } from './utils';

function RequestServices() {
  const { query } = useRequestProcessor();
  const {
    widthDeviceSize,
    isMobile,
  } = useWindowWidthSize();
  const [queryParams, setQueryParams] = useState({
    page: 1,
  });
  const [modalState, setModalState] = useState({
    visibleModal: false,
    title: '',
    action: null,
    data: null,
  });
  const [apiNotification, contextHolder] = notification.useNotification();
  const [responsiveDesign, setResponsiveDesign] = useState(false);

  const { data, isLoading } = query(
    'services',
    () => RequestedServicesAPI.getRequestedServices(queryParams),
    { enabled: true },
    queryParams,
  );

  const toggleModal = () => {
    setModalState((currentState) => ({
      ...currentState,
      visibleModal: !currentState.visibleModal,
    }));
  };

  const onSuccess = (message) => {
    apiNotification.success({
      description: message,
    });
    setModalState((currentState) => ({
      title: '',
      action: null,
      visibleModal: !currentState.visibleModal,
    }));
  };

  const handleOnClickCreateRequestService = () => {
    toggleModal();
    setModalState((currentState) => ({
      ...currentState,
      title: <TitleModal Icon={CarOutlined} title="Crear solicitud de servicio" />,
      action: 'create',
      data: null,
    }));
  };

  const handleActions = (menuItemClicked, record) => {
    const { key } = menuItemClicked;

    if (key === 'inactive') {
      setModalState(() => ({
        visibleModal: true,
        title: <TitleModal Icon={CarOutlined} title="Eliminar solicitud de servicio" />,
        action: 'inactive',
        data: record,
      }));
    } else if (key === 'update') {
      setModalState(() => ({
        visibleModal: true,
        title: <TitleModal Icon={CarOutlined} title="Reprogramar solicitud de servicio" />,
        action: 'update',
        data: record,
      }));
    }
  };

  const handleChangeTable = (pag) => {
    const page = pag.current;
    setQueryParams({
      ...queryParams,
      page,
    });
  };

  const handleChangeSearch = (e) => {
    const { value } = e.target;
    if (value.length > 0) {
      setQueryParams({
        ...queryParams,
        search: value,
      });
    } else {
      setQueryParams((current) => {
        const copy = { ...current };
        delete copy.search;
        return copy;
      });
    }
  };

  useEffect(() => {
    if (widthDeviceSize <= 1024) setResponsiveDesign(true);
    else setResponsiveDesign(false);
  }, [widthDeviceSize]);

  return (
    <>
      {contextHolder}
      <PageHeader title="Solicitud de servicio" />

      <Row justify="space-between">
        <Col xs={24} md={8}>
          <Input
            placeholder="Buscar número de requisición o cliente"
            onChange={handleChangeSearch}
          />
        </Col>
        <Col xs={24} md={8}>
          <Button
            type="primary"
            className={`bg-blue-800 ${isMobile && 'mt-4'}`}
            block={isMobile}
            style={{
              float: 'right',
              marginBottom: '2rem',
            }}
            onClick={handleOnClickCreateRequestService}
          >
            Crear solicitud de servicio
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          { isLoading
            ? <Loader />
            : (
              <TableStyled
                size="middle"
                columns={columns(handleActions)}
                dataSource={data.services}
                rowKey={(record) => record.id}
                onChange={handleChangeTable}
                pagination={{
                  defaultCurrent: 1,
                  total: data.total,
                  pageSize: 10,
                }}
                expandable={{
                  expandedRowRender,
                  expandRowByClick: true,
                  showExpandColumn: true,
                }}
                scroll={{ x: responsiveDesign }}
              />
            )}
        </Col>
      </Row>

      <DrawerStyled
        open={modalState.visibleModal}
        width="650px"
        centered
        title={modalState.title}
        onClose={toggleModal}
        footer={null}
      >
        <ActionsRequestService
          action={modalState.action}
          onSuccess={onSuccess}
          mobileDevice={isMobile}
          data={modalState.data}
        />
      </DrawerStyled>
    </>
  );
}

export default RequestServices;
