import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';

export const dateFormat = (date) => {
  const startDate = dayjs(date).locale('es-mx').format(
    'DD / MMMM / YYYY',
  );
  return startDate;
};

export const moneyFormat = (money) => Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
}).format(money);

export const daysBetweenDates = (startDate, endDate) => {
  const dateStartFormat = new Date(startDate);
  const dateEndFormat = new Date(endDate);
  const difference = dateStartFormat.getTime() - dateEndFormat.getTime();
  const totalDays = Math.ceil(difference / (1000 * 3600 * 24));

  return totalDays;
};
