import ContainerLayout from 'Layouts/ContainerLayout';
import CustomerLogo from 'Shared/Icons/rating.png';
import DriverLogo from 'Shared/Icons/driver.png';
import VendorLogo from 'Shared/Icons/enterprise.png';
import EmployeeLogo from 'Shared/Icons/employees.png';
import OrdersLogo from 'Shared/Icons/choices.png';
import InsuranceLogo from 'Shared/Icons/insurance.png';
import TruckLogo from 'Shared/Icons/truck.png';
import PlatformLogo from 'Shared/Icons/flat-bed-truck.png';
import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'antd';
import styled from 'styled-components';

const CardStyled = styled(Card)`
  :hover{
    background-color: #1D1D1B;
    color: #FFFFFF;
  }
  .ant-card-body{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 150px;
    span{
      display: block;
      width: 100%;
      font-weight: 500;
      margin-top: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
  }
`;

const RowStyled = styled(Row)`
  margin-bottom: 1rem;
`;

const IconsStyled = styled.img`
  display: block;
  max-width: 100%;
  height: 48px;
`;

function MainDashboard() {
  return (
    <ContainerLayout boxShadow="none">
      <RowStyled justify="space-evenly" align="middle" gutter={[16, 20]}>
        <Col xs={24} sm={12} lg={5}>
          <Link to="/clients">
            <CardStyled>
              <IconsStyled src={CustomerLogo} />
              <span>Clientes</span>
            </CardStyled>
          </Link>
        </Col>
        <Col xs={24} sm={12} lg={5}>
          <Link to="/drivers">
            <CardStyled>
              <IconsStyled src={DriverLogo} />
              <span>Conductores</span>
            </CardStyled>
          </Link>
        </Col>
        <Col xs={24} sm={12} lg={5}>
          <Link to="/vendors">
            <CardStyled>
              <IconsStyled src={VendorLogo} />
              <span>Proveedores</span>
            </CardStyled>
          </Link>

        </Col>
        <Col xs={24} sm={12} lg={5}>
          <Link to="/employees">
            <CardStyled>
              <IconsStyled src={EmployeeLogo} />
              <span>Recursos</span>
            </CardStyled>
          </Link>
        </Col>
      </RowStyled>
      <Row justify="space-evenly" align="middle" gutter={[16, 20]}>
        <Col xs={24} sm={12} lg={5}>
          <Link to="/request-service">
            <CardStyled>
              <IconsStyled src={OrdersLogo} />
              <span>Servicios</span>
            </CardStyled>
          </Link>
        </Col>
        <Col xs={24} sm={12} lg={5}>
          <Link to="/insurances">
            <CardStyled>
              <IconsStyled src={InsuranceLogo} />
              <span>Pólizas</span>
            </CardStyled>
          </Link>
        </Col>
        <Col xs={24} sm={12} lg={5}>
          <Link to="/trucks">
            <CardStyled>
              <IconsStyled src={TruckLogo} />
              <span>Unidades</span>
            </CardStyled>
          </Link>
        </Col>
        <Col xs={24} sm={12} lg={5}>
          <Link to="/platforms">
            <CardStyled>
              <IconsStyled src={PlatformLogo} />
              <span>Plataformas</span>
            </CardStyled>
          </Link>
        </Col>
      </Row>
    </ContainerLayout>
  );
}

export default MainDashboard;
