import axiosConfig from 'Shared/Utils/axiosConfig';

function getClients() {
  return axiosConfig.get('clients')
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function createClient(params) {
  return axiosConfig.post('clients', params)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function updateClient(clientId, params) {
  return axiosConfig.patch(`clients/${clientId}`, params)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function removeClient(clientId) {
  return axiosConfig.delete(`clients/${clientId}`)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

export const ClientsAPI = {
  getClients,
  createClient,
  updateClient,
  removeClient,
};
