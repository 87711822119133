import PageHeader from 'Shared/Components/PageHeader';
import FormItem from 'Shared/Components/Form/Item';
import { useNavigate } from 'react-router-dom';
import {
  Row, Col, Form, Input, Button,
} from 'antd';
import React from 'react';

function ViewClientService() {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    const { serviceNo } = values;

    navigate('/services/information', { state: { serviceNo } });
  };

  return (
    <>
      <PageHeader title="Servicios" />

      <Row align="middle" justify="center">
        <Col xs={24} lg={12}>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <FormItem
              label="Ingresa el número de servicio"
              name="serviceNo"
              help="Recuerda que el número de servicio debes de ingresarlo tal cual te fue enviado."
              rules={[
                { required: true, message: 'Ingresa el nombre de servicio.' },
              ]}
            >
              <Input />
            </FormItem>
            <Row type="flex" justify="end">
              <Col>
                <Button
                  type="primary"
                  className="bg-blue-800"
                  htmlType="submit"
                  style={{ marginTop: '1.3rem' }}
                >
                  Buscar servicio
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default ViewClientService;
