import React from 'react';

function TitleModal(props) {
  const { title, Icon } = props;

  return (
    <>
      <Icon
        style={{
          marginRight: 10,
          color: '#1D1D1B',
          fontSize: '1.3rem',
        }}
      />
      {title}
    </>
  );
}

export default TitleModal;
