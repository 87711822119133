import { useWindowWidthSize } from 'Shared/customHooks';
import { Drawer as DrawerAntd } from 'antd';
import React, { useState, useEffect } from 'react';

function Drawer(props) {
  const { children, width } = props;
  const { isMobile } = useWindowWidthSize();
  const [widthDrawer, setWidthDrawer] = useState('');

  useEffect(() => {
    if (isMobile) {
      setWidthDrawer('100vw');
    } else if (width) {
      setWidthDrawer(width);
    } else {
      setWidthDrawer('550px');
    }
  }, [isMobile]);

  return (
    <DrawerAntd {...props} width={widthDrawer}>
      {children}
    </DrawerAntd>
  );
}

export default Drawer;
