import PageHeader, { SubPageHeader } from 'Shared/Components/PageHeader';
import { useWindowWidthSize, useRequestProcessor } from 'Shared/customHooks';
import ModalStyled from 'Shared/Components/Modal';
import TitleModal from 'Shared/Components/TitleModal';
import { CardFinances } from 'Shared/Components/CardFinances';
import ActionsExpenses from 'Finance/Expenses/Actions';
import { AvatarCategoriesExpenses } from 'Finance/Expenses/utils';
import { dateFormat, moneyFormat } from 'Shared/Utils';
import { TransactionsAPI } from 'Shared/Services/transactions';
import { CarOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Button,
  Statistic,
  List,
  Skeleton,
} from 'antd';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import React, { useState } from 'react';
import FilterExpenses from './filter';
import 'dayjs/locale/es-mx';

dayjs.extend(quarterOfYear);

function Expenses() {
  const { query } = useRequestProcessor();
  const {
    isMobile,
  } = useWindowWidthSize();
  const [modalState, setModalState] = useState({
    visibleModal: false,
    title: '',
    action: null,
    data: null,
  });
  const [queryParams, setQueryParams] = useState({
    isExpense: 1,
  });

  const { data, isLoading } = query(
    'get-transactions-expenses',
    () => TransactionsAPI.getTransactions(queryParams),
    { enabled: true },
    queryParams,
  );

  const { data: dataCategories, isLoading: isLoadingCategories } = query(
    'get-expenses-categories',
    () => TransactionsAPI.getExpensesSum(queryParams),
    { enabled: true },
    queryParams,
  );

  const toggleModal = () => {
    setModalState((currentState) => ({
      ...currentState,
      visibleModal: !currentState.visibleModal,
    }));
  };

  const onSuccess = () => {
    setModalState((currentState) => ({
      title: '',
      action: null,
      visibleModal: !currentState.visibleModal,
    }));
  };

  const handleOnClickCreateDrivers = () => {
    toggleModal();
    setModalState((currentState) => ({
      ...currentState,
      title: <TitleModal Icon={CarOutlined} title="Crear gasto" />,
      action: 'create',
      data: null,
    }));
  };

  /*
  const handleActions = (menuItemClicked, record) => {
    const { key } = menuItemClicked;

    if (key === 'inactive') {
      setModalState(() => ({
        visibleModal: true,
        title: <TitleModal Icon={CarOutlined} title="Eliminar gasto" />,
        action: 'inactive',
        data: record,
      }));
    } else if (key === 'update') {
      setModalState(() => ({
        visibleModal: true,
        title: <TitleModal Icon={CarOutlined} title="Actualizar gasto" />,
        action: 'update',
        data: record,
      }));
    }
  };
  */

  const onSubmit = (values) => {
    const {
      lapse,
      sort,
      dateLapse,
      categories,
    } = values;
    let startDate;
    let endDate;

    if (lapse === 'month') {
      startDate = dayjs(dateLapse).startOf('month').format('YYYY-MM-DD');
      endDate = dayjs(dateLapse).endOf('month').format('YYYY-MM-DD');
    }

    if (lapse === 'year') {
      startDate = dayjs(dateLapse).startOf('year').format('YYYY-MM-DD');
      endDate = dayjs(dateLapse).endOf('year').format('YYYY-MM-DD');
    }

    if (lapse === 'quarter') {
      startDate = dayjs(dateLapse).startOf('quarter').format('YYYY-MM-DD');
      endDate = dayjs(dateLapse).endOf('quarter').format('YYYY-MM-DD');
    }

    setQueryParams({
      ...queryParams,
      startDate,
      endDate,
      sort,
      categories,
    });
  };

  return (
    <>
      <PageHeader title="Gastos" />

      <Row justify="end">
        <Col xs={24} lg={24}>
          <Button
            type="primary"
            className="bg-blue-800"
            block={isMobile}
            style={{
              float: 'right',
              marginBottom: '2rem',
              marginLeft: '0.5rem',
            }}
            onClick={handleOnClickCreateDrivers}
          >
            Crear gasto
          </Button>
          <FilterExpenses onSubmit={onSubmit} />
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <SubPageHeader title="Categorias" />
        </Col>
      </Row>

      <Row gutter={20} style={{ flexWrap: 'nowrap', overflowX: 'scroll', paddingBottom: 20 }}>
        {isLoadingCategories ? (
          <>
            <Col xs={21} md={5}>
              <Skeleton active />
            </Col>
            <Col xs={21} md={5}>
              <Skeleton active />
            </Col>
            <Col xs={21} md={5}>
              <Skeleton active />
            </Col>
            <Col xs={21} md={5}>
              <Skeleton active />
            </Col>
          </>
        ) : (
          dataCategories && dataCategories.categoriesExpenses.map((expense) => (
            <Col xs={21} md={5} key={expense.category}>
              <CardFinances>
                <Statistic
                  title={expense.category}
                  value={expense.totalAmount}
                  precision={2}
                  valueStyle={{
                    color: '#1200A0',
                  }}
                  prefix="-$"
                />
              </CardFinances>
            </Col>
          ))
        )}
      </Row>

      <Row>
        <Col xs={24}>
          <SubPageHeader title="Transacciones" />
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          <List
            itemLayout="horizontal"
            dataSource={data && data.transactions}
            loading={isLoading}
            pagination={{
              position: 'bottom',
              align: 'center',
              pageSize: 10,
            }}
            locale={{
              emptyText: 'No hay transacciones.',
            }}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={(
                    <AvatarCategoriesExpenses
                      key={index}
                      category={item.category}
                    />
                  )}
                  title={item.description}
                  description={dateFormat(item.transactionDate)}
                />
                <div>
                  {`-${moneyFormat(item.amount)}`}
                </div>
              </List.Item>
            )}
          />
        </Col>
      </Row>

      <ModalStyled
        open={modalState.visibleModal}
        centered
        title={modalState.title}
        onCancel={toggleModal}
        footer={null}
      >
        <ActionsExpenses
          action={modalState.action}
          onSuccess={onSuccess}
          mobileDevice={isMobile}
          data={modalState.data}
        />
      </ModalStyled>
    </>
  );
}

export default Expenses;
