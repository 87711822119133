import TitleExpandedRowStyled, { SubtitleExpandedRowStyled } from 'Shared/Components/TitleExpandedRow';
import DescriptionsStyled from 'Shared/Components/Descriptions';
import { dateFormat, moneyFormat } from 'Shared/Utils';
import {
  Row,
  Col,
} from 'antd';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

function ShowRequestedService(props) {
  const { numRequisition, data, title } = props;
  const [formatTitle, setFormatTitle] = useState('');
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    if (pathname === '/in-service') setFormatTitle(`Servicio ${numRequisition}`);
    else if (pathname === '/schedule-service') setFormatTitle(`Solicitud de servicio ${numRequisition}`);
    else if (pathname === '/end-service') setFormatTitle(`Finalizar servicio ${numRequisition}`);
  }, [title]);

  return (
    <Row className="mb-10">
      <Col xs={24}>
        <DescriptionsStyled
          title={
            (
              <TitleExpandedRowStyled>
                {formatTitle}
              </TitleExpandedRowStyled>
            )
          }
          bordered
          size="small"
        />
        <DescriptionsStyled title={<SubtitleExpandedRowStyled>Datos del solicitante</SubtitleExpandedRowStyled>} bordered size="small">
          <DescriptionsStyled.Item span={3} label="Nombre">
            {data.client.fullName}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item label="RFC">
            {data.client.rfc}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item span={2} label="Contacto">
            {data.client.phone}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item label="Correo electrónico">
            {data.client.email}
          </DescriptionsStyled.Item>
        </DescriptionsStyled>
        <DescriptionsStyled title={<SubtitleExpandedRowStyled>Características de la carga</SubtitleExpandedRowStyled>} bordered size="small">
          <DescriptionsStyled.Item span={2} label="Fecha de carga">
            {dateFormat(data.dateFreight)}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item label="Fecha de entrega">
            {dateFormat(data.dateDelivery)}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item span={2} label="Lugar de origen">
            {data.placeOrigin}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item label="Lugar de destino">
            {data.placeDestination}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item span={1} label="Modalidad">
            {data.mode}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item span={1} label="Tipo de carga">
            {data.typeFreight}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item span={1} label="Tipo de traslado">
            {data.typeMove}
          </DescriptionsStyled.Item>
          {data.observation && (
            <DescriptionsStyled.Item span={3} label="Observaciones">
              {data.observation}
            </DescriptionsStyled.Item>
          )}
        </DescriptionsStyled>
        <DescriptionsStyled title={<SubtitleExpandedRowStyled>Referencias</SubtitleExpandedRowStyled>} bordered size="small">
          {data.billLandings && (
            <DescriptionsStyled.Item span={2} label="Contenedores">
              {data.billLandings}
            </DescriptionsStyled.Item>
          )}
          <DescriptionsStyled.Item label="Unidad">
            {`${data.assignTruck.truck.name}(${data.assignTruck.truck.model})  ${data.assignTruck.truck.tag}`}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item span={1} label="Operador">
            {`${data.assignTruck.driver.fullName}`}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item span={1} label="Tarifa de venta">
            {moneyFormat(data.rateSale)}
          </DescriptionsStyled.Item>
        </DescriptionsStyled>
      </Col>
    </Row>
  );
}

export default ShowRequestedService;
