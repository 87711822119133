import PageHeader from 'Shared/Components/PageHeader';
import { useWindowWidthSize, useRequestProcessor } from 'Shared/customHooks';
import Loader from 'Shared/Components/Loader';
import ModalStyled from 'Shared/Components/Modal';
import TitleModal from 'Shared/Components/TitleModal';
import TableStyled from 'Shared/Components/Table';
import ActionsTrucks from 'Transportation/Trucks/Actions';
import { TrucksAPI } from 'Shared/Services/trucks';
import { CarOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Button,
  Input,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { columns, expandedRowRender } from './utils';

function Trucks() {
  const { query } = useRequestProcessor();
  const [queryParams, setQueryParams] = useState({});
  const {
    widthDeviceSize,
    isMobile,
  } = useWindowWidthSize();
  const [modalState, setModalState] = useState({
    visibleModal: false,
    title: '',
    action: null,
    data: null,
  });
  const [responsiveDesign, setResponsiveDesign] = useState(false);

  const { data, isLoading } = query(
    'trucks',
    () => TrucksAPI.getTrucks(queryParams),
    { enabled: true },
    queryParams,
  );

  const toggleModal = () => {
    setModalState((currentState) => ({
      ...currentState,
      visibleModal: !currentState.visibleModal,
    }));
  };

  const onSuccess = () => {
    setModalState((currentState) => ({
      title: '',
      action: null,
      visibleModal: !currentState.visibleModal,
    }));
  };

  const handleOnClickCreateTrucks = () => {
    toggleModal();
    setModalState((currentState) => ({
      ...currentState,
      title: <TitleModal Icon={CarOutlined} title="Crear unidad" />,
      action: 'create',
      data: null,
    }));
  };

  const handleActions = (menuItemClicked, record) => {
    const { key } = menuItemClicked;

    if (key === 'inactive') {
      setModalState(() => ({
        visibleModal: true,
        title: <TitleModal Icon={CarOutlined} title="Eliminar unidad" />,
        action: 'inactive',
        data: record,
      }));
    } else if (key === 'update') {
      setModalState(() => ({
        visibleModal: true,
        title: <TitleModal Icon={CarOutlined} title="Actualizar unidad" />,
        action: 'update',
        data: record,
      }));
    }
  };

  const handleChangeSearch = (e) => {
    const { value } = e.target;

    if (value.length > 0) {
      setQueryParams({
        ...queryParams,
        search: value,
      });
    } else {
      setQueryParams((current) => {
        const copy = { ...current };
        delete copy.search;
        return copy;
      });
    }
  };

  const handleChangeTable = (pag) => {
    const page = pag.current;
    setQueryParams({
      ...queryParams,
      page,
    });
  };

  useEffect(() => {
    if (widthDeviceSize <= 1024) setResponsiveDesign(true);
    else setResponsiveDesign(false);
  }, [widthDeviceSize]);

  return (
    <>
      <PageHeader title="Unidades" />

      <Row justify="space-between">
        <Col xs={24} md={8}>
          <Input
            placeholder="Buscar unidad"
            onChange={handleChangeSearch}
          />
        </Col>
        <Col xs={24} md={8}>
          <Button
            type="primary"
            className="bg-blue-800"
            block={isMobile}
            style={{
              float: 'right',
              marginBottom: '2rem',
            }}
            onClick={handleOnClickCreateTrucks}
          >
            Crear unidad
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          { isLoading
            ? <Loader />
            : (
              <TableStyled
                size="middle"
                columns={columns(handleActions)}
                dataSource={data.trucks}
                rowKey={(record) => record.id}
                onChange={handleChangeTable}
                pagination={{
                  defaultCurrent: 1,
                  total: data.total,
                  pageSize: 5,
                }}
                expandable={{
                  expandedRowRender,
                  expandRowByClick: true,
                  showExpandColumn: true,
                }}
                scroll={{ x: responsiveDesign }}
              />
            )}
        </Col>
      </Row>

      <ModalStyled
        open={modalState.visibleModal}
        centered
        title={modalState.title}
        onCancel={toggleModal}
        footer={null}
      >
        <ActionsTrucks
          action={modalState.action}
          onSuccess={onSuccess}
          mobileDevice={isMobile}
          data={modalState.data}
        />
      </ModalStyled>
    </>
  );
}

export default Trucks;
