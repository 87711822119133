import { AuthAPI } from 'Shared/Services/auth';
import { useRequestProcessor } from 'Shared/customHooks';
import React, {
  useState, createContext, useContext, useMemo,
} from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [user, setUser] = useState(() => {
    const userProfile = localStorage.getItem('userProfile');
    if (userProfile) {
      return JSON.parse(userProfile);
    }
    return null;
  });
  const { mutation } = useRequestProcessor();
  const navigate = useNavigate();

  const { mutateAsync } = mutation('login', (values) => AuthAPI.login(values));

  const login = async (payload) => {
    let responseLogin = '';
    try {
      const response = await mutateAsync(payload);
      const { fullName, username } = response;
      const formatResponse = {
        fullName,
        username,
      };

      localStorage.setItem(
        'userProfile',
        JSON.stringify(formatResponse),
      );
      localStorage.setItem('sessionExpired', '0');
      setUser(formatResponse);
      navigate('/dashboard');
    } catch (error) {
      const { response } = error;
      const { data } = response;
      responseLogin = data;
    }

    return responseLogin;
  };

  const logout = async () => {
    await AuthAPI.logout();
    localStorage.removeItem('userProfile');
    setUser(null);
    navigate('/');
  };

  const value = useMemo(() => ({
    user,
    login,
    logout,
  }), [user, login, logout]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  return useContext(AuthContext);
}

function RequireAuth({ children }) {
  const location = useLocation();

  if (!localStorage.getItem('userProfile')) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export {
  AuthProvider,
  RequireAuth,
  useAuth,
};
