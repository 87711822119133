import styled from 'styled-components';
import { Descriptions } from 'antd';

const DescriptionsStyled = styled(Descriptions)`
  .ant-descriptions-header {
    margin-bottom: 0;
    border: 1px solid rgba(5, 5, 5, 0.06);
    border-bottom-width: 0;
  }

  .ant-descriptions-view .ant-descriptions-item-label {
    color: #000;
    background-color: #89a7e8;
    border-bottom: 1px solid #fff;
    font-weight: 700;
  }

  .ant-descriptions-view .ant-descriptions-item-content {
    background-color: #fff;

    & .ant-descriptions > .ant-descriptions-view {
      border-radius: 0;
      border: 0;

      & .ant-descriptions-row:first-child > th:first-child {
        border-top-left-radius: 0;
      }

      & .ant-descriptions-row > th {
        border-right: 1px solid #fff;
      }
      & .ant-descriptions-row > th:last-child {
        border-right: 0;
      }
    }
  }

  .ant-descriptions-view .ant-descriptions-row > th.no-label {
    padding: 0;
  }

  .ant-descriptions-view {
    border-radius: 0;
  }


`;

DescriptionsStyled.defaultProps = {
};

export default DescriptionsStyled;
