import PageHeader from 'Shared/Components/PageHeader';
import { SubtitleExpandedRowStyled } from 'Shared/Components/TitleExpandedRow';
import { useRequestProcessor } from 'Shared/customHooks';
import { dateFormat, moneyFormat } from 'Shared/Utils';
import Loader from 'Shared/Components/Loader';
import DescriptionsStyled from 'Shared/Components/Descriptions';
import { RequestedServicesAPI } from 'Shared/Services/services';
import { UserOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Row, Col, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';

const items = [
  {
    key: '1',
    label: 'Caracteristicas',
  },
  {
    key: '2',
    label: 'Referencias',
  },
  {
    key: '3',
    label: 'Ubicación',
  },
  {
    key: '4',
    label: 'Evidencias',
  },
];

function ViewInformationService() {
  const [tabActivated, setTabActivated] = useState('1');
  const [numRequisition, setNumRequisition] = useState(null);
  const [triggerRequest, setTriggerRequest] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const { query } = useRequestProcessor();

  const { data, isLoading, isError } = query(
    ['requisition', numRequisition],
    () => RequestedServicesAPI.getNumRequisition(numRequisition),
    { enabled: triggerRequest },
  );

  useEffect(() => {
    if (!state) {
      navigate('/services');
    } else {
      const { serviceNo } = state;
      setNumRequisition(serviceNo);
      setTriggerRequest(true);
    }
  }, []);

  const onChangeTab = (key) => {
    setTabActivated(key);
  };

  return (
    <>
      <PageHeader title={`${state.serviceNo}`} />

      {isLoading && (
        <Row align="middle" justify="center" className="mt-32">
          <Col lg={12}>
            <Loader />
          </Col>
        </Row>
      )}

      {!isLoading && data && (
        <>
          <Row align="middle" justify="space-around" className="mb-7">
            <Col>
              <strong className="mr-2">
                Estado:
              </strong>
              {`${data.status}`}
            </Col>
            <Col>
              <UserOutlined className="mr-2" />
              <strong className="mr-2">
                Ejecutivo a cargo del servicio:
              </strong>
              {`${data.user.fullName}`}
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col xs={24} lg={12}>
              <Tabs defaultActiveKey="1" centered items={items} onChange={onChangeTab} />
            </Col>
          </Row>
          <Row align="middle">
            <Col xs={24} lg={24}>
              {tabActivated === '1' && (
                <>
                  <DescriptionsStyled title={<SubtitleExpandedRowStyled>Características de la carga</SubtitleExpandedRowStyled>} bordered size="small">
                    <DescriptionsStyled.Item span={2} label="Fecha de carga">
                      {dateFormat(data.dateFreight)}
                    </DescriptionsStyled.Item>
                    <DescriptionsStyled.Item label="Fecha de entrega">
                      {dateFormat(data.dateDelivery)}
                    </DescriptionsStyled.Item>
                    <DescriptionsStyled.Item span={2} label="Lugar de origen">
                      {data.placeOrigin}
                    </DescriptionsStyled.Item>
                    <DescriptionsStyled.Item label="Lugar de destino">
                      {data.placeDestination}
                    </DescriptionsStyled.Item>
                    <DescriptionsStyled.Item span={1} label="Modalidad">
                      {data.mode}
                    </DescriptionsStyled.Item>
                    <DescriptionsStyled.Item span={1} label="Tipo de carga">
                      {data.typeFreight}
                    </DescriptionsStyled.Item>
                    <DescriptionsStyled.Item span={1} label="Tipo de traslado">
                      {data.typeMove}
                    </DescriptionsStyled.Item>
                    {data.observation && (
                      <DescriptionsStyled.Item span={3} label="Observaciones">
                        {data.observation}
                      </DescriptionsStyled.Item>
                    )}
                  </DescriptionsStyled>
                  <DescriptionsStyled title={<SubtitleExpandedRowStyled>Conceptos</SubtitleExpandedRowStyled>} bordered size="small">
                    {data.concepts && data.concepts.map((concept, idx) => (
                      <>
                        <DescriptionsStyled.Item
                          span={1}
                          label={idx + 1}
                          labelStyle={{
                            width: '127px',
                            textAlign: 'center',
                            borderRight: '1px solid #fff',
                          }}
                          contentStyle={{
                            display: 'none',
                          }}
                        />
                        <DescriptionsStyled.Item
                          span={1}
                          label="Descripción"
                          labelStyle={{
                            width: '150px',
                            textAlign: 'center',
                            borderRight: '1px solid #fff',
                          }}
                        >
                          {concept.description}
                        </DescriptionsStyled.Item>
                        <DescriptionsStyled.Item span={2} label="Importe total">
                          {moneyFormat(concept.priceConcept)}
                        </DescriptionsStyled.Item>
                      </>
                    ))}
                  </DescriptionsStyled>
                </>
              )}

              {tabActivated === '2' && (
                <DescriptionsStyled title={<SubtitleExpandedRowStyled>Referencias</SubtitleExpandedRowStyled>} bordered size="small">
                  {data.billLandings && (
                    <DescriptionsStyled.Item span={3} label="Contenedores">
                      {data.billLandings}
                    </DescriptionsStyled.Item>
                  )}
                  <DescriptionsStyled.Item span={3} label="Unidad">
                    {`${data.assignTruck.truck.name}(${data.assignTruck.truck.model})  ${data.assignTruck.truck.tag}`}
                  </DescriptionsStyled.Item>
                  <DescriptionsStyled.Item span={1} label="Operador">
                    {`${data.assignTruck.driver.fullName}`}
                  </DescriptionsStyled.Item>
                </DescriptionsStyled>
              )}

              {tabActivated === '3' && (
                <Row justify="center" style={{ marginTop: '2rem' }}>
                  <Col xs={24}>
                    {data.mirror_url ? (
                      <iframe
                        src={data.mirror_url}
                        title="Map"
                        height="600"
                        style={{
                          border: 0,
                          width: '100%',
                        }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      />
                    ) : (
                      <span className="block text-center">
                        La ubicación se reflejará cuando el estado sea &quot;En servicio&quot;
                      </span>
                    )}
                  </Col>
                </Row>
              )}

              {tabActivated === '4' && (
                <Row justify="center" style={{ marginTop: '2rem' }}>
                  <Col xs={24}>
                    <span className="block text-center">
                      Las evidencias se reflejarán cuando el estado sea &quot;En servicio&quot;
                    </span>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </>
      )}

      {isError && !isLoading && (
        <Row align="middle" justify="center">
          <Col xs={24}>
            No existe el número de requisición.
          </Col>
        </Row>
      )}

    </>
  );
}

export default ViewInformationService;
