import Actions from 'Shared/Components/Actions';
import TitleExpandedRowStyled, { SubtitleExpandedRowStyled } from 'Shared/Components/TitleExpandedRow';
import DescriptionsStyled from 'Shared/Components/Descriptions';
import { dateFormat, moneyFormat } from 'Shared/Utils';
import {
  Button,
  Space,
  Row,
  Col,
} from 'antd';
import {
  EditFilled,
  DownloadOutlined,
  TruckOutlined,
  StopOutlined,
  CalendarOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import React from 'react';

function DisabledReScheduled(record) {
  const { status } = record;

  if (
    status === 'Abierto'
    || status === 'Reprogramado'
  ) return false;

  return true;
}

const items = (handleActions, record) => [
  {
    label: 'Reprogramar',
    key: 'update',
    disabled: DisabledReScheduled(record),
    icon: <EditFilled style={{ color: '#AD2AA5' }} />,
    onClick: (item) => {
      handleActions(item, record);
    },
  },
];

export const columns = (handleActions) => [
  {
    title: 'Número de requisición',
    dataIndex: 'numRequisition',
    sorter: (a, b) => a.numRequisition.length - b.numRequisition.length,
    fixed: 'left',
    width: 350,
  },
  {
    title: 'Fecha de solicitud',
    dataIndex: 'created',
    render: (text) => dateFormat(text),
  },
  {
    title: 'Cliente',
    dataIndex: ['client', 'fullName'],
  },
  {
    title: 'Fecha de carga',
    dataIndex: 'dateFreight',
    render: (text) => dateFormat(text),
  },
  {
    title: 'Tarifa de venta',
    dataIndex: 'rateSale',
    render: (text) => moneyFormat(text),
  },
  {
    title: 'Destino',
    dataIndex: 'placeDestination',
  },
  {
    title: 'Estado',
    dataIndex: 'status',
    render: (text) => {
      let textFormat = text;

      if (text === 'Programar') textFormat = 'Programado';

      return textFormat;
    },
  },
  {
    key: 'action',
    width: 20,
    fixed: 'right',
    render: (record) => (
      <Actions
        menu={{
          items: items(handleActions, record),
        }}
        placement="bottomRight"
      />
    ),
  },
];

export const expandedRowRender = (record, index, indent, expanded) => {
  let render = null;
  if (expanded) {
    const {
      numRequisition,
      client,
      placeOrigin,
      placeDestination,
      mode,
      typeFreight,
      typeMove,
      observation,
      dateDelivery,
      dateFreight,
      billLandings,
      rateSale,
      assignTruck,
      status,
      concepts,
      id,
    } = record;

    render = (
      <>
        <DescriptionsStyled.Item label="">
          <Row justify="space-between">
            <Col>
              <Space
                align="end"
                space="middle"
                className="mx-2 mb-3"
              >
                {status === 'Abierto' && (
                  <Link
                    to="/schedule-service"
                    state={{
                      ...record,
                      title: 'Programar servicio',
                    }}
                  >
                    <Button
                      type="primary"
                      className="bg-teal-950"
                      icon={<CalendarOutlined />}
                    >
                      Programar servicio
                    </Button>
                  </Link>
                )}
                {status === 'Programar' && (
                  <Link
                    to="/in-service"
                    state={{
                      ...record,
                      title: 'Entrar en servicio',
                    }}
                  >
                    <Button
                      type="primary"
                      className="bg-sky-800"
                      icon={<TruckOutlined />}
                    >
                      Entrar en servicio
                    </Button>
                  </Link>
                )}

                {status === 'En servicio' && (
                  <Link
                    to="/end-service"
                    state={{
                      ...record,
                      title: 'Finalizar servicio',
                    }}
                  >
                    <Button
                      type="primary"
                      className="bg-emerald-800"
                      icon={<CheckOutlined />}
                    >
                      Finalizar servicio
                    </Button>
                  </Link>
                )}

                {(status === 'Programar' || status === 'Abierto' || status === 'Reprogramar') && (
                  <Button
                    type="primary"
                    className="bg-red-700"
                    icon={<StopOutlined />}
                    href={`/request-service/download/${id}`}
                    target="_blank"
                  >
                    Cancelar
                  </Button>
                )}
              </Space>
            </Col>
            <Col>
              <Space
                align="end"
                space="middle"
                className="mx-2 mb-3"
              >
                <Button
                  type="primary"
                  className="bg-blue-800"
                  icon={<DownloadOutlined />}
                  href={`/request-service/download/${id}`}
                  target="_blank"
                >
                  Descargar
                </Button>
                {/* <Button
                  type="primary"
                  className="bg-sky-800"
                  icon={<MailOutlined />}
                  href={`/download-requested-service/${id}`}
                  target="_blank"
                >
                  Enviar por correo
                </Button> */}
              </Space>
            </Col>
          </Row>
        </DescriptionsStyled.Item>
        <DescriptionsStyled
          title={
            (
              <TitleExpandedRowStyled>
                {numRequisition}
              </TitleExpandedRowStyled>
            )
          }
          bordered
          size="small"
        />
        <DescriptionsStyled title={<SubtitleExpandedRowStyled>Datos del solicitante</SubtitleExpandedRowStyled>} bordered size="small">
          <DescriptionsStyled.Item span={3} label="Nombre">
            {client.fullName}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item label="RFC">
            {client.rfc}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item span={2} label="Contacto">
            {client.phone}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item label="Correo electrónico">
            {client.email}
          </DescriptionsStyled.Item>
        </DescriptionsStyled>
        <DescriptionsStyled title={<SubtitleExpandedRowStyled>Características de la carga</SubtitleExpandedRowStyled>} bordered size="small">
          <DescriptionsStyled.Item span={2} label="Fecha de carga">
            {dateFormat(dateFreight)}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item label="Fecha de entrega">
            {dateFormat(dateDelivery)}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item span={2} label="Lugar de origen">
            {placeOrigin}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item label="Lugar de destino">
            {placeDestination}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item span={1} label="Modalidad">
            {mode}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item span={1} label="Tipo de carga">
            {typeFreight}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item span={1} label="Tipo de traslado">
            {typeMove}
          </DescriptionsStyled.Item>
          {observation && (
            <DescriptionsStyled.Item span={3} label="Observaciones">
              {observation}
            </DescriptionsStyled.Item>
          )}
        </DescriptionsStyled>
        <DescriptionsStyled title={<SubtitleExpandedRowStyled>Referencias</SubtitleExpandedRowStyled>} bordered size="small">
          {billLandings && (
            <DescriptionsStyled.Item span={2} label="Contenedores">
              {billLandings}
            </DescriptionsStyled.Item>
          )}
          <DescriptionsStyled.Item label="Unidad">
            {`${assignTruck.truck.name}(${assignTruck.truck.model})  ${assignTruck.truck.tag}`}
          </DescriptionsStyled.Item>
          <DescriptionsStyled.Item span={1} label="Operador">
            {`${assignTruck.driver.fullName}`}
          </DescriptionsStyled.Item>
        </DescriptionsStyled>
        <DescriptionsStyled title={<SubtitleExpandedRowStyled>Conceptos</SubtitleExpandedRowStyled>} bordered size="small">
          {concepts && concepts.map((concept, idx) => (
            <>
              <DescriptionsStyled.Item
                span={1}
                label={idx + 1}
                labelStyle={{
                  width: '150px',
                  textAlign: 'center',
                  borderRight: '1px solid #fff',
                }}
                contentStyle={{
                  display: 'none',
                }}
              />
              <DescriptionsStyled.Item span={1} label="Descripción">
                {concept.description}
              </DescriptionsStyled.Item>
              <DescriptionsStyled.Item span={2} label="Importe total">
                {moneyFormat(concept.priceConcept)}
              </DescriptionsStyled.Item>
            </>
          ))}
          <DescriptionsStyled.Item
            labelStyle={{
              width: '150px',
              textAlign: 'center',
              borderRight: '1px solid #fff',
            }}
            label="Tarifa de venta"
          >
            {moneyFormat(rateSale)}
          </DescriptionsStyled.Item>
        </DescriptionsStyled>
      </>
    );
  }

  return render;
};
