import React from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip,
  GeoJSON,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

const DefaultIcon = L.icon({
  iconUrl:
    'https://cdn4.iconfinder.com/data/icons/small-n-flat/24/map-marker-512.png',
  shadowUrl: iconShadow,
  iconSize: [50, 50],
});

L.Marker.prototype.options.icon = DefaultIcon;

function DisplayMap({
  lat = 51.505,
  long = -0.09,
  zoom = 15,
  tooltipDescription = '',
  geoJsonData = null,
}) {
  return (
    <MapContainer
      center={[lat, long]}
      zoom={zoom}
      scrollWheelZoom={false}
      style={{
        width: '100%',
        height: '750px',
      }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {!geoJsonData && (
        <Marker icon={DefaultIcon} position={[lat, long]}>
          {tooltipDescription && (
            <Tooltip permanent direction="bottom" offset={[0, 20]}>{tooltipDescription}</Tooltip>
          )}
        </Marker>
      )}
      {geoJsonData && (
        <GeoJSON
          data={geoJsonData}
          key="my-geo-json"
        />
      )}
    </MapContainer>
  );
}

export default DisplayMap;
