// import { AuthAPI } from 'Shared/Services/auth';
import axios from 'axios';

const { REACT_APP_API } = process.env;

const NO_TOKEN_URL = ['login', 'users/forgot', 'users/reset'];

function cleanJSON(data) {
  if (data) {
    Object.keys(data).forEach((key) => {
      // eslint-disable-next-line no-unused-expressions
      (data[key] === undefined && delete data[key])
      || (data[key] === '' && delete data[key])
      || (data[key] === null && delete data[key]);
    });
  }
  return data;
}

export function getCookie(name) {
  console.log('document', document);
  console.log('cookie', document.cookie);

  const value = `; ${document.cookie}`;
  console.log('valsssue', value);
  const parts = value.split(`; ${name}=`);
  console.log('parts', parts);
  console.log('LENGT', parts.length);
  if (parts.length === 2) {
    // console.log('pass', parts.pop().split(';').shift());
    return parts.pop().split(';').shift();
  }
  console.log('aquitoy');
  return null;
}

const apiClient = axios.create({
  baseURL: REACT_APP_API,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
});

apiClient.interceptors.request.use(
  (config) => {
    const { method, data, url } = config;
    if (!NO_TOKEN_URL.includes(url)) {
      const { headers } = config;
      console.log('headers', headers);
      config = {
        ...config,
        headers: {
          ...headers,
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
      };
      // config.headers['X-CSRF-TOKEN'] = getCookie('csrf_access_token');
      console.log('config', config);
    }
    if (['post', 'put'].includes(method)) { config.data = cleanJSON(data); }

    return config;
  },
  (error) => {
    console.log('error', error);
    return Promise.reject(error);
  },
);

// Response interceptor for API calls
apiClient.interceptors.response.use((response) => response, async (error) => {
  if (error.response.status === 401) {
    localStorage.setItem('sessionExpired', '1');
    localStorage.removeItem('userProfile');
    // window.location.href = '/';

    return Promise.reject(error);
  }

  return Promise.reject(error);
});

export default apiClient;
