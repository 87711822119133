import CreateUpdateVendors from 'Contacts/Vendors/Actions/CreateUpdate';
import DeleteVendors from 'Contacts/Vendors/Actions/Delete';
import React from 'react';

const ActionsVendors = (props) => {
  const {
    action, mobileDevice, onSuccess, data,
  } = props;

  const renderAction = (typeAction) => {
    let actionRender = null;

    if (typeAction === 'create' || typeAction === 'update') {
      actionRender = (
        <CreateUpdateVendors
          mobileDevice={mobileDevice}
          onSuccess={onSuccess}
          data={data}
          typeAction={typeAction}
        />
      );
    } else if (typeAction === 'inactive') {
      actionRender = (
        <DeleteVendors
          data={data}
          mobileDevice={mobileDevice}
          onSuccess={onSuccess}
        />
      );
    }

    return actionRender;
  };

  return renderAction(action);
};

export default ActionsVendors;
