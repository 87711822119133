/* eslint-disable */
import { useRequestProcessor } from 'Shared/customHooks';
import FormItem from 'Shared/Components/Form/Item';
import SelectClients from 'Shared/Selects/Clients';
import DescriptionsStyled from 'Shared/Components/Descriptions';
import { SubtitleExpandedRowStyled } from 'Shared/Components/TitleExpandedRow';
import SelectEmployees from 'Shared/Selects/Employees';
import SelectModalidad from 'Shared/Selects/Modalidad';
import SelectTraslado from 'Shared/Selects/Traslado';
import SelectCarga from 'Shared/Selects/SelectCarga';
import SelectAvailableTrucks from 'Shared/Selects/AvailableTrucks';
import SelectAvailableDrivers from 'Shared/Selects/AvailableDrivers';
import { RequestedServicesAPI } from 'Shared/Services/services';
import {
  Row, Col, Button, Form, notification, Input, DatePicker, InputNumber, Card, Space,
} from 'antd';
import {
  CloseOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

function CreateRequestService(props) {
  const {
    mobileDevice,
    onSuccess,
    typeAction,
    data,
  } = props;
  const { mutation } = useRequestProcessor();
  const [apiNotification, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const dateFreight = Form.useWatch('dateFreight', form);
  const concepts = Form.useWatch('concepts', form);
  const [disabledAvailableTrucks, setAvailableTrucks] = useState(true);
  const [dateAvailableTrucks, setDateAvailableTrucks] = useState(null);
  const [dateAvailableEndTrucks, setDateAvailableEndTrucks] = useState(null);
  const [rawDateAvailableTrucks, setRawDateAvailableTrucks] = useState(null);
  const [rawDateAvailableEndTrucks, setRawDateAvailableEndTrucks] = useState(null);
  const [textAvailableTrucks, setTextAvailableTrucks] = useState('Unidades disponibles');
  const [textAvailableDrivers, setTextAvailableDrivers] = useState('Operadores dispomnibles');
  const [truckUpdated, setTruckUpdated] = useState(null);
  const [driverUpdated, setDriverUpdated] = useState(null);
  const {
    isLoading: isLoadingCreate,
    mutate: mutateCreate,
  } = mutation('createRequestedService', (values) => RequestedServicesAPI.createRequestedServices(values));

  const {
    isLoading: isLoadingUpdate,
    mutate: mutateUpdate,
  } = mutation('updateRequestedService', ({ id, values }) => RequestedServicesAPI.updateRequestedServices(id, values));

  const onFinish = async (values) => {
    const formatDateFreight = dayjs(values.dateFreight).format('YYYY-MM-DD');
    const formatDateDelivery = dayjs(values.dateDelivery).format('YYYY-MM-DD');

    const formatValues = {
      ...values,
      dateFreight: formatDateFreight,
      dateDelivery: formatDateDelivery,
    };

    if (typeAction === 'create') {
      mutateCreate(formatValues, {
        onSuccess: (response) => {
          const { message } = response;
          apiNotification.success({
            description: message,
          });
          form.resetFields();
          onSuccess(message);
        },
        onError: (error) => {
          const { data: info } = error.response;
          const { message } = info;
          apiNotification.error({
            description: message,
          });
        },
      });
    } else if (typeAction === 'update') {
      const { id } = data;
      mutateUpdate({
        id,
        values: {
          ...formatValues,
          status: 'Reprogramado',
        },
      }, {
        onSuccess: (response) => {
          const { message } = response;
          apiNotification.success({
            description: message,
          });
          form.resetFields();
          onSuccess();
        },
        onError: (error) => {
          const { data: info } = error.response;
          const { message } = info;
          apiNotification.error({
            description: message,
          });
        },
      });
    }
  };

  const handleChangeDateFreight = async (date) => {
    const formatDate = dayjs(date).format('YYYY-MM-DD');
    setRawDateAvailableTrucks(date);
    setDateAvailableTrucks(formatDate);
    setTruckUpdated(null);
    setDriverUpdated(null);
    form.setFieldsValue({
      truckId: null,
      driverId: null,
    });
  };

  const handleChangeDateDelivery = async (date) => {
    const formatDate = dayjs(date).format('YYYY-MM-DD');
    setRawDateAvailableEndTrucks(date);
    setDateAvailableEndTrucks(formatDate);
    setTruckUpdated(null);
    setDriverUpdated(null);
    form.setFieldsValue({
      truckId: null,
      driverId: null,
    });
  };

  useEffect(() => {
    if (dateAvailableTrucks && dateAvailableEndTrucks) {
      let rawDateFreight = rawDateAvailableTrucks;
      let rawDateDelivery = rawDateAvailableEndTrucks;

      if (typeAction === 'update' && truckUpdated && driverUpdated) {
        const { dateFreight, dateDelivery } = data;
        rawDateFreight = dateFreight;
        rawDateDelivery = dateDelivery;
        setRawDateAvailableTrucks(rawDateFreight);
        setRawDateAvailableEndTrucks(rawDateDelivery);
      }
      const formatRawDate = dayjs(rawDateFreight).locale('es').format('DD MMMM YYYY');
      const formatRawDateEnd = dayjs(rawDateDelivery).locale('es').format('DD MMMM YYYY');
      setTextAvailableTrucks(`Unidades disponibles para el ${formatRawDate} - ${formatRawDateEnd}`);
      setTextAvailableDrivers(`Operadores disponibles para el ${formatRawDate} - ${formatRawDateEnd}`);
      setAvailableTrucks(false);
    } else {
      setTextAvailableTrucks('Unidades disponibles');
      setTextAvailableDrivers('Operadores disponibles');
    }
  }, [dateAvailableTrucks, dateAvailableEndTrucks]);

  useEffect(() => {
    if (typeAction === 'create') {
      form.resetFields();
    } else {
      const { dateFreight, dateDelivery } = data;
      const formatDateFreight = dayjs(dateFreight).format('YYYY-MM-DD');
      const formatDateDelivery = dayjs(dateDelivery).format('YYYY-MM-DD');

      form.setFieldsValue({
        ...data,
        clientId: data?.client?.id,
        userId: data?.user?.id,
        dateFreight: dayjs(dateFreight),
        dateDelivery: dayjs(dateDelivery),
        price: data?.rateSale,
        truckId: data?.assignTruck?.truck?.id,
        driverId: data?.assignTruck?.driver?.id,
      });

      setTruckUpdated({
        ...data?.assignTruck?.truck,
      });
      setDriverUpdated({
        ...data?.assignTruck?.driver,
      });
      setAvailableTrucks(false);
      setDateAvailableTrucks(formatDateFreight);
      setDateAvailableEndTrucks(formatDateDelivery);
    }
  }, [form, data, typeAction]);

  useEffect(() => {
    if (Array.isArray(concepts) && concepts.length > 0) {
      const sumPrice = concepts
        .filter((conc) => conc && conc.hasOwnProperty('priceConcept'))
        .reduce(
          (accumulator, currentValue) => accumulator + currentValue.priceConcept,
          0,
        );

      form.setFieldsValue({
        price: sumPrice,
      });
    } else {
      form.setFieldsValue({
        price: null,
      });
    }
  }, [concepts]);

  return (
    <>
      {contextHolder}
      <Form
        className="login-form"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          ...data,
          clientId: data?.client?.id,
          userId: data?.user?.id,
          dateFreight: dayjs(data?.dateFreight),
          dateDelivery: dayjs(data?.dateDelivery),
          price: data?.rateSale,
          truckId: data?.assignTruck?.truck?.id,
          driverId: data?.assignTruck?.driver?.id,
        }}
        scrollToFirstError={{
          behavior: 'smooth',
        }}
      >
        <DescriptionsStyled
          title={
            <SubtitleExpandedRowStyled>Datos del solicitante</SubtitleExpandedRowStyled>
          }
          bordered
          size="small"
          className="pb-3"
        >
          <Row type="flex">
            <Col xs={24}>
              <FormItem
                label="Selecciona un cliente"
                name="clientId"
                rules={[
                  { required: true, message: 'Selecciona el cliente.' },
                ]}
              >
                <SelectClients />
              </FormItem>
            </Col>
            <Col xs={24}>
              <FormItem
                label="Selecciona un ejecutivo"
                name="userId"
                rules={[
                  { required: true, message: 'Selecciona el ejecutivo.' },
                ]}
              >
                <SelectEmployees />
              </FormItem>
            </Col>
          </Row>
        </DescriptionsStyled>

        <DescriptionsStyled
          title={
            <SubtitleExpandedRowStyled>Ruta de Carga</SubtitleExpandedRowStyled>
          }
          bordered
          size="small"
          className="pb-3"
        >
          <Row type="flex">
            <Row type="flex" gutter={24}>
              <Col xs={24} md={12}>
                <FormItem
                  label="Lugar de origen"
                  name="placeOrigin"
                  rules={[
                    { required: true, message: 'Ingresa el lugar de origen.' },
                  ]}
                >
                  <Input />
                </FormItem>
              </Col>
              <Col xs={24} md={12}>
                <FormItem
                  label="Lugar de destino"
                  name="placeDestination"
                  rules={[
                    { required: true, message: 'Ingresa el lugar de destino.' },
                  ]}
                >
                  <Input />
                </FormItem>
              </Col>
            </Row>
          </Row>
        </DescriptionsStyled>

        <DescriptionsStyled
          title={
            <SubtitleExpandedRowStyled>Caracteristicas de la Carga</SubtitleExpandedRowStyled>
          }
          bordered
          size="small"
          className="pb-3"
        >
          <Row type="flex">
            <Row type="flex" gutter={24}>
              <Col xs={24} md={12}>
                <FormItem
                  label="Modalidad"
                  name="mode"
                  rules={[
                    { required: true, message: 'Ingresa la modalidad.' },
                  ]}
                >
                  <SelectModalidad />
                </FormItem>
              </Col>
              <Col xs={24} md={12}>
                <FormItem
                  label="Tipo de traslado"
                  name="typeMove"
                  rules={[
                    { required: true, message: 'Ingresa el tipo de traslado.' },
                  ]}
                >
                  <SelectTraslado />
                </FormItem>
              </Col>
            </Row>
            <Col xs={24}>
              <FormItem
                label="Tipo de carga"
                name="typeFreight"
                rules={[
                  { required: true, message: 'Ingresa el tipo de carga.' },
                ]}
              >
                <SelectCarga />
              </FormItem>
            </Col>

            <Row type="flex" gutter={24}>

              <Col xs={24} md={12}>
                <FormItem
                  label="Fecha de carga"
                  name="dateFreight"
                  rules={[
                    { required: true, message: 'Selecciona la fecha de carga.' },
                  ]}
                >
                  <DatePicker
                    allowClear={false}
                    placeholder=""
                    onChange={handleChangeDateFreight}
                  />
                </FormItem>
              </Col>

              <Col xs={24} md={12}>
                <FormItem
                  label="Fecha de entrega"
                  name="dateDelivery"
                  rules={[
                    { required: true, message: 'Selecciona la fecha de entrega.' },
                  ]}
                >
                  <DatePicker
                    disabled={!dateFreight}
                    disabledDate={(current) => current && dateFreight > current}
                    onChange={handleChangeDateDelivery}
                    allowClear={false}
                    placeholder=""
                  />
                </FormItem>
              </Col>
            </Row>

            <Col xs={24}>
              <FormItem
                label="Observaciones o servicios adicionales"
                name="observation"
              >
                <Input.TextArea />
              </FormItem>
            </Col>
          </Row>
        </DescriptionsStyled>

        <DescriptionsStyled
          title={
            <SubtitleExpandedRowStyled>Referencias</SubtitleExpandedRowStyled>
          }
          bordered
          size="small"
          className="pb-3"
        >
          <Row type="flex">
            <Col xs={24}>
              <FormItem
                label="Contenedor(es)"
                name="billLanding"
                rules={[
                  { pattern: /^[a-zA-Z0-9_ ]*$/, message: 'Solo ingresa letras y/o números.' },
                ]}
              >
                <Input />
              </FormItem>
            </Col>
            <Col xs={24}>
              <FormItem
                label={textAvailableTrucks}
                name="truckId"
                rules={[
                  { required: true, message: 'Selecciona una unidad.' },
                ]}
              >
                <SelectAvailableTrucks
                  disabled={disabledAvailableTrucks}
                  date={dateAvailableTrucks}
                  dateEnd={dateAvailableEndTrucks}
                  preload={truckUpdated}
                />
              </FormItem>
            </Col>
            <Col xs={24}>
              <FormItem
                label={textAvailableDrivers}
                name="driverId"
                rules={[
                  { required: true, message: 'Selecciona un operador.' },
                ]}
              >
                <SelectAvailableDrivers
                  disabled={disabledAvailableTrucks}
                  date={dateAvailableTrucks}
                  dateEnd={dateAvailableEndTrucks}
                  preload={driverUpdated}
                />
              </FormItem>
            </Col>
          </Row>
        </DescriptionsStyled>

        <DescriptionsStyled
          title={
            <SubtitleExpandedRowStyled>Conceptos</SubtitleExpandedRowStyled>
          }
          bordered
          size="small"
          className="pb-3"
        >
          <Row type="flex">
            <Col xs={24}>
              <Form.List name="concepts">
                {(fields, { add, remove }) => (
                  <Row type="flex">
                    <Col xs={24}>
                      {fields.map((field) => (
                        <DescriptionsStyled
                          key={field.key}
                          title={
                            <SubtitleExpandedRowStyled>
                              {`Concepto ${field.name + 1}`}
                            </SubtitleExpandedRowStyled>
                          }
                          bordered
                          size="small"
                          className="pb-3"
                        >
                          <Row type="flex">
                            <Row type="flex" gutter={20}>
                              <Col xs={24} md={12}>
                                <Form.Item label="Descripción" name={[field.name, 'description']}>
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col xs={24} md={12}>
                                <Form.Item label="Importe total" name={[field.name, 'priceConcept']}>            
                                  <InputNumber
                                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    className="w-full"
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24}>
                                <Button
                                  danger
                                  block
                                  onClick={() => remove(field.name)}
                                >
                                  {`Eliminar concepto ${field.name + 1}`}
                                </Button>
                              </Col>
                            </Row>
                          </Row>
                        </DescriptionsStyled>
                      ))}
          
                      <Button
                        icon={<PlusOutlined />}
                        className="mb-4"
                        type="dashed"
                        onClick={() => add()}
                        block
                      >
                        Agregar concepto
                      </Button>
                    </Col>
                  </Row>
                )}
              </Form.List>
            </Col>
            <Col xs={24}>
              <FormItem
                label="Tarifa de venta"
                name="price"
                rules={[
                  { required: true, message: 'Ingresa la tarifa de la venta.' },
                ]}
              >
                <InputNumber
                  disabled
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  className="w-full"
                />
              </FormItem>
            </Col>
          </Row>
        </DescriptionsStyled>
        <Row type="flex" justify="end">
          <Col>
            <Button
              type="primary"
              className="bg-blue-800"
              loading={isLoadingCreate || isLoadingUpdate}
              htmlType="submit"
              block={mobileDevice}
              style={{ marginTop: '1.3rem' }}
            >
              {typeAction === 'create' ?
              'Crear solicitud de servicio' :
              'Reprogramar '}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default CreateRequestService;
