import { Avatar } from 'antd';
import React, { useEffect, useState } from 'react';

export function useCategoriesShort(category) {
  const [style, setStyle] = useState(null);
  const [shortName, setShortName] = useState(null);

  useEffect(() => {
    if (category && category.name) {
      switch (category.name) {
        case 'Prestamo':
          setShortName('P');
          setStyle({
            backgroundColor: '#A0003E',
            color: '#FFF',
          });
          break;
        case 'Seguros':
          setShortName('S');
          setStyle({
            backgroundColor: '#1200A0',
            color: '#FFF',
          });
          break;
        case 'Creditos bancarios + Prestamos':
          setShortName('CP');
          setStyle({
            backgroundColor: '#6200A0',
            color: '#FFF',
          });
          break;
        case 'Nomina':
          setShortName('N');
          setStyle({
            backgroundColor: '#6200A0',
            color: '#FFF',
          });
          break;
        case 'Arrendamientos':
          setShortName('A');
          setStyle({
            backgroundColor: '#4c416d',
            color: '#FFF',
          });
          break;
        case 'Impuestos':
          setShortName('I');
          setStyle({
            backgroundColor: '#4c416d',
            color: '#FFF',
          });
          break;
        case 'Seguro Social':
          setShortName('SO');
          setStyle({
            backgroundColor: '#4c416d',
            color: '#FFF',
          });
          break;
        case 'Aportaciones':
          setShortName('A');
          setStyle({
            backgroundColor: '#4c416d',
            color: '#FFF',
          });
          break;
        case 'Gastos Operativos':
          setShortName('GO');
          setStyle({
            backgroundColor: '#4c416d',
            color: '#FFF',
          });
          break;
        case 'Gastos Administrativos':
          setShortName('GA');
          setStyle({
            backgroundColor: '#1200A0',
            color: '#FFF',
          });
          break;
        default:
          setShortName('NA');
          setStyle({
            backgroundColor: '#FFF',
            color: '#000',
          });
      }
    }
  }, []);

  return { style, shortName };
}

export function AvatarCategoriesExpenses(props) {
  const { category, ...rest } = props;
  const { style, shortName } = useCategoriesShort(category);

  return (
    <Avatar
      style={style}
      {...rest}
    >
      {shortName}
    </Avatar>
  );
}
