import { useRequestProcessor } from 'Shared/customHooks';
import { VendorsAPI } from 'Shared/Services/vendors';
import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const SelectVendors = React.forwardRef(({ ...props }, ref) => {
  const { style, disabled, filter } = props;
  const { query } = useRequestProcessor();

  const { data, isLoading } = query(
    'selectVendors',
    () => VendorsAPI.getVendors(),
    { enabled: true },
  );

  return (
    <Select
      {...props}
      ref={ref}
      style={{
        ...style,
        width: style && style.width ? style.width : '100%',
      }}
      disabled={disabled}
      loading={isLoading}
    >
      <Option key="own" value="own">
        Servicio propio
      </Option>
      {data && data.vendors.map((ven) => (
        <Option key={ven.id} value={ven.id} filter={filter}>
          {`${ven.fullName} / ${ven.rfc}`}
        </Option>
      ))}
    </Select>
  );
});

export default SelectVendors;
