import { useRequestProcessor } from 'Shared/customHooks';
import { ClientsAPI } from 'Shared/Services/clients';
import React from 'react';
import {
  Row, Col, Button, Form, Input, notification,
} from 'antd';

const { Item: FormItem } = Form;

function DeleteClients(props) {
  const { mobileDevice, data, onSuccess } = props;
  const { mutation } = useRequestProcessor();
  const [apiNotification, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const {
    isLoading,
    mutate,
  } = mutation('removeClient', (values) => ClientsAPI.removeClient(values));

  const onFinish = async (values) => {
    const { clientId } = values;
    mutate(clientId, {
      onSuccess: (response) => {
        const { message } = response;
        apiNotification.success({
          description: message,
        });
        form.resetFields();
        onSuccess();
      },
      onError: (error) => {
        const { data: info } = error.response;
        const { message } = info;
        apiNotification.error({
          description: message,
        });
      },
    });
  };

  return (
    <>
      {contextHolder}
      <Form
        onFinish={onFinish}
        colon={false}
        hideRequiredMark
        initialValues={{ clientId: data.id }}
      >
        <FormItem
          label="clientId"
          name="clientId"
          hidden
        >
          <Input />
        </FormItem>
        <Row type="flex" justify="center" gutter={[48, 48]}>
          <Col>
            ¿Estás seguro de eliminar a
            {' '}
            {data.fullName}
            ?
          </Col>
        </Row>
        <Row type="flex" justify="end">
          <Col>
            <Button
              type="primary"
              danger
              loading={isLoading}
              htmlType="submit"
              block={mobileDevice}
              style={{ marginTop: '1.3rem' }}
            >
              Eliminar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default DeleteClients;
