import PageHeader from 'Shared/Components/PageHeader';
import { useWindowWidthSize, useRequestProcessor } from 'Shared/customHooks';
import TableStyled from 'Shared/Components/Table';
import ModalStyled from 'Shared/Components/Modal';
import TitleModal from 'Shared/Components/TitleModal';
import Loader from 'Shared/Components/Loader';
import ActionsUser from 'Contacts/Employees/Actions';
import { UsersAPI } from 'Shared/Services/users';
import { IdcardOutlined } from '@ant-design/icons';
import {
  Row, Col, Button,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { columns } from './utils';

function Employees() {
  const { query } = useRequestProcessor();
  const {
    widthDeviceSize,
    isMobile,
  } = useWindowWidthSize();
  const [modalState, setModalState] = useState({
    visibleModal: false,
    title: '',
    action: null,
    data: null,
  });
  const [responsiveDesign, setResponsiveDesign] = useState(false);

  const { data, isLoading } = query(
    'users',
    () => UsersAPI.getUsers(),
    { enabled: true },
  );

  const toggleModal = () => {
    setModalState((currentState) => ({
      ...currentState,
      visibleModal: !currentState.visibleModal,
    }));
  };

  const onSuccess = () => {
    setModalState((currentState) => ({
      title: '',
      action: null,
      visibleModal: !currentState.visibleModal,
    }));
  };

  const handleOnClickCreateUser = () => {
    toggleModal();
    setModalState((currentState) => ({
      ...currentState,
      title: <TitleModal Icon={IdcardOutlined} title="Crear usuario" />,
      action: 'create',
      data: null,
    }));
  };

  const handleActions = (menuItemClicked, record) => {
    const { key } = menuItemClicked;

    if (key === 'inactive') {
      setModalState(() => ({
        visibleModal: true,
        title: <TitleModal Icon={IdcardOutlined} title="Eliminar usuario" />,
        action: 'inactive',
        data: record,
      }));
    }
  };

  useEffect(() => {
    if (widthDeviceSize <= 1024) setResponsiveDesign(true);
    else setResponsiveDesign(false);
  }, [widthDeviceSize]);

  return (
    <>
      <PageHeader title="Recursos" />

      <Row justify="end">
        <Col xs={24} lg={24}>
          <Button
            type="primary"
            className="bg-blue-800"
            block={isMobile}
            style={{
              float: 'right',
              marginBottom: '2rem',
            }}
            onClick={handleOnClickCreateUser}
          >
            Crear recurso
          </Button>
        </Col>
      </Row>

      <Row>
        <Col xs={24}>
          { isLoading
            ? <Loader />
            : (
              <TableStyled
                size="middle"
                columns={columns(handleActions)}
                rowKey={(record) => record.id}
                dataSource={data.users}
                pagination={{
                  defaultCurrent: 1,
                  total: data.total,
                }}
                scroll={{ x: responsiveDesign }}
              />
            )}
        </Col>
      </Row>

      <ModalStyled
        open={modalState.visibleModal}
        centered
        title={modalState.title}
        onCancel={toggleModal}
        footer={null}
      >
        <ActionsUser
          action={modalState.action}
          onSuccess={onSuccess}
          mobileDevice={isMobile}
          data={modalState.data}
        />
      </ModalStyled>

    </>
  );
}

export default Employees;
