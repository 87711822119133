import axiosConfig from 'Shared/Utils/axiosConfig';

function getPlatforms() {
  return axiosConfig.get('platforms')
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function createPlatform(params) {
  return axiosConfig.post('platforms', params)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function updatePlatform(platformId, params) {
  return axiosConfig.patch(`platforms/${platformId}`, params)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

function removePlatform(platformId) {
  return axiosConfig.delete(`platforms/${platformId}`)
    .then((response) => {
      const { data } = response;
      return data;
    });
}

export const PlatformsAPI = {
  getPlatforms,
  createPlatform,
  updatePlatform,
  removePlatform,
};
