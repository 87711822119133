import { useRequestProcessor } from 'Shared/customHooks';
import { TrucksAPI } from 'Shared/Services/trucks';
import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const SelectTrucks = React.forwardRef(({ ...props }, ref) => {
  const { style, disabled, filter } = props;
  const { query } = useRequestProcessor();

  const { data, isLoading } = query(
    'selectTrucks',
    () => TrucksAPI.getTrucks(),
    { enabled: true },
  );

  return (
    <Select
      {...props}
      ref={ref}
      style={{
        ...style,
        width: style && style.width ? style.width : '100%',
      }}
      disabled={disabled}
      loading={isLoading}
    >
      {data && data.trucks.map((truck) => (
        <Option key={truck.id} value={truck.id} filter={filter}>
          {`(${truck.reference}) ${truck.name}`}
        </Option>
      ))}
    </Select>
  );
});

export default SelectTrucks;
