import CreateInsurances from 'Insurances/Actions/Create';
import RenewInsurances from 'Insurances/Actions/Renew';
import React from 'react';

const ActionsInsurances = (props) => {
  const {
    action, mobileDevice, onSuccess, data,
  } = props;
  const renderAction = (typeAction) => {
    let actionRender = null;
    if (typeAction === 'create') {
      actionRender = (
        <CreateInsurances
          mobileDevice={mobileDevice}
          onSuccess={onSuccess}
          data={data}
          typeAction={typeAction}
        />
      );
    }

    if (typeAction === 'renew') {
      actionRender = (
        <RenewInsurances
          mobileDevice={mobileDevice}
          onSuccess={onSuccess}
          data={data}
          typeAction={typeAction}
        />
      );
    }

    return actionRender;
  };

  return renderAction(action);
};

export default ActionsInsurances;
