import CreateScheduled from 'Transportation/Scheduled/Actions/Create';
import DeletePlatforms from 'Transportation/Platforms/Actions/Delete';
import React from 'react';

const ActionsScheduled = (props) => {
  const {
    action, mobileDevice, onSuccess, data,
  } = props;
  const renderAction = (typeAction) => {
    let actionRender = null;
    if (typeAction === 'create') {
      actionRender = (
        <CreateScheduled
          mobileDevice={mobileDevice}
          onSuccess={onSuccess}
          data={data}
          typeAction={typeAction}
        />
      );
    } else if (typeAction === 'inactive') {
      actionRender = (
        <DeletePlatforms
          data={data}
          mobileDevice={mobileDevice}
          onSuccess={onSuccess}
        />
      );
    }

    return actionRender;
  };

  return renderAction(action);
};

export default ActionsScheduled;
